.ngx-dialog-content {
	width: 30% !important;
	padding: 0 !important;
	text-align: left;
}

.ngx-dialog-body {
	border-bottom: 1px solid #cecece;
}

.ngx-dialog {
	z-index: 9999!important;
}

.ngx-dialog-header {
	padding: 0 !important;
	margin: 0 !important;
}

.ngx-dialog-body {
	padding: 20px 10px !important;
	text-align: left !important;
}

.ngx-dialog-header h4 {
	padding: 5px 10px !important;
	margin: 0 !important;
	line-height: 26px;
	color: #ffffff !important;
	border-bottom: 1px solid #c6c6c6;
	text-align: left !important;
}

.dialog-error .ngx-dialog-header h4 {
	background: red !important;
}

.dialog-success .ngx-dialog-header h4 {
	background: #0d7836 !important;
}

.ngx-dialog-footer {
	text-align: left !important;
	margin: 0 !important;
}

.ok-btn {
	color: #ffffff;
	font-weight: bold;
	cursor: pointer;
	height: 33px;
	padding: 7px 15px!important;
	font-family: sfui-regular;
}

.dialog-error .ok-btn {
	background: red !important;
}

.dialog-success .ok-btn {
	background: #0d7836 !important;
}

.dialog-error-no-close .ngx-dialog-header h4 {
	background: red !important;
}

.dialog-error-no-close .ok-btn {
	background: red !important;
}

.dialog-error-no-close .ngx-dialog-header span.close-dialog {
	display: none;
}