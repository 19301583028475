* {
	margin: 0;
	padding: 0;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

html {
	scroll-behavior: smooth;
	height: 100%;
}

@font-face {
	font-family: sfui-regular;
	src: url('../../assets/fonts/sfuitext-regular.ttf');
}

@font-face {
	font-family: sfui-semibold;
	src: url('../../assets/fonts/sf-ui-display-semibold.ttf');
}

@page {
	margin: .15in .50in .20in .50in;
}

body {
	background: #fff;
	color: #434343 !important;
	font-size: 14px;
	overflow-x: hidden;
	min-height: 100%;
	width: 100%;
	-webkit-appearance: none;
	-moz-appearance: none;
	overflow-y: auto;
	height: 100%;
	appearance: none;
	font-family: "sfui-regular";
	/* font-family:"Paypalsans" ; */
}

a {
	color: #434343;
	text-decoration: none;
}

a:focus,
a:hover {
	text-decoration: none;
}

*,
input,
select,
textarea,
option,
button {
	outline: none !important;
}

ul {
	list-style: none;
}

h1,
h2,
h3,
h4,
h5 {
	width: 100%;
	padding-bottom: 10px;
}

.pb10 {
	padding-bottom: 10px;
}

.pb-0 {
	padding-bottom: 0;
}

.w50 {
	width: 50%;
	float: left;
	padding: 10px 0 0;
}

.green-text {
	color: #0D7836;
}

.green_bg {
	background-color: #0D7836;
}

.text_center {
	text-align: center;
}

.pointer {
	cursor: pointer;
}

.page {
	padding: 50px 0 0;
}

.absolute {
	position: absolute;
}

.padding-left7 {
	padding-left: 7px;
}

.mb15 {
	margin-bottom: 15px;
}

.font-regular {
	font-family: sfui-regular;
}

.width100 {
	width: 100%;
}

.align-left {
	text-align: left;
}

.grid-container {
	display: grid;
	grid-gap: 15px;
	grid-template-columns: 33% 33% 32%;
}

.confirm-text {
	padding-left: 10px;
}

.grid-container-two {
	display: grid;
	grid-gap: 15px;
	grid-template-columns: 64% 33%;
}

.grid-guideline {
	display: grid;
	grid-template-columns: calc(50% - 10px) calc(50% - 10px);
	gap: 20px;
}

.grid-box {
	padding: 5px 0 0;
}



nav.header-session {
	height: 76px;
	padding: 10px 0;
}

.grid-payment {
	display: grid;
	grid-gap: 15px;
	grid-template-columns: 28% 45% 25%;
	width: 100%;
}

.grid-date {
	display: grid;
	grid-gap: 10px;
	grid-template-columns: auto 150px auto;
	width: 100%;
}

.grid-payment-box {
	border: 1px solid #cacbca;
	border-bottom: 5px solid #0d7836;
	border-radius: 4px;
	position: relative;
}

.submit-cancel {
	font-size: 14px;
}

.grid-two {
	display: grid;
	grid-gap: 15px;
	grid-template-columns: 49% 49%;
	width: 100%;
}

.grid-one-third {
	display: grid;
	grid-gap: 20px;
	grid-template-columns: 68% 30%;
}

.grid-two-box form {
	padding: 0 12px 0 0;
}

td.upload-support-doc {
	width: 75%;
}

td.upload-support-doc-btn {
	width: 25%;
}

.grid-two-box {
	padding: 10px 0 0;
}

.grid-third {
	display: grid;
	grid-gap: 15px;
	grid-template-columns: 32% 32% 32%;
}

.grid-modal-container {
	display: grid;
	grid-gap: 15px;
	grid-template-columns: 42% 42% 20%;
	align-items: center;
}

.grid-modal-box.search-app {
	margin-top: 16px;
}

.profile_table.standard-prof-table td {
	color: #434343;
	font-weight: normal;
}

.grid-modal-box {
	padding: 10px 0 0;
}

.profile_container {
	display: grid;
	grid-gap: 20px;
	grid-template-columns: 15% 41% 41%;
}

.profile_box {
	padding-right: 10px;
	border-right: 1px solid #9d9d9d;
}

.pay_btn.pay-height {
	height: auto !important;
}

.profile_box:last-child {
	border-right: 0;
}

.search_grid-upload-img .grid-box {
	width: 33.3%;
}

.panel-title {
	font-size: 20px;
	font-weight: bold;
	line-height: 19px;
	padding-bottom: 10px;
}

.dropdown-menu-top li {
	margin-top: 0 !important;
}

img.logo {
	width: 172px;
}

.text-center {
	text-align: center;
}

p {
	width: 100%;
}

.mid-section p {
	text-align: justify;
}

.verify-picture-grid .guidlines p {
	padding: 0 0 0 5px;
}

.visatype_box {
	padding: 20px 0;
}

.common_view_item {
	width: 15%;
}

.catgories_box {
	box-shadow: 0 0 4px rgba(0, 0, 0, 0.09), 0 4px 6px rgba(0, 0, 0, 0.09);
	padding: 17px;
}

.captcha-actions span.tooltip {
	top: 42px;
	bottom: inherit;
}

.captcha-actions span.tooltipArrow {
	border-bottom-color: hsla(0, 0%, 47.5%, .93);
	border-top-color: transparent;
	top: -12px;
	bottom: inherit;
}

.personal-head {
	position: relative;
	padding-left: 5px;
}

span.tooltip {
	display: none;
	float: left;
	background-color: hsla(0, 0%, 47.5%, .93);
	color: #fff;
	padding: 3px 10px;
	border-radius: 3px;
	width: 280px;
	font-size: 12px;
	position: absolute;
	z-index: 1010;
	bottom: 42px;
	left: 0;
	text-align: left;
}

span.tooltipArrow {
	content: " ";
	height: 0;
	position: absolute;
	width: 0;
	border: 7px solid transparent;
	border-top-color: hsla(0, 0%, 47.5%, .93);
	bottom: -12px;
	left: 12px;
}

textarea+.tooltip {
	top: 117px;
}

/* 8 March start */
.image_upload_container {
	width: calc(100% - 20px);
	max-width: 1100px;
	padding: 0 !important;
	background: #fff;
	border-radius: 0;
	margin: 0 auto;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.modal-header {
	background: #0D7836;
	color: #fff;
	text-align: left;
	height: 45px;
}

.modal-header i {
	text-align: right;
	color: #fff;
}

.modal-header h4 {
	width: 100%;
	float: left;
	padding: 0;
	font-weight: 400;
}

.confirmbox-container .modal-header,
.confirmbox-container .modal-body,
.confirmbox-container .modal-footer {
	text-align: left !important;
}

.confirmbox-container .modal-body p span {
	color: red;
}

.guidelines-container .modal-body {
	height: calc(100vh - 125px);
	overflow: hidden;
	overflow-y: auto;
}

.image_upload_container .centered {
	position: relative;
	margin: 0 auto;
	height: 100%;
	overflow-y: auto;
}

.image_upload_container .centered .grid-one-third,
.image_upload_container .file-upload {
	height: 100%;
}

.image_upload_container .form_box+.file-upload {
	height: calc(100% - 74px);
}

.image_upload_container .centered .grid-boxes {
	height: 100%;
}

.image_upload_container .centered .grid-boxes-upload {
	overflow-y: auto;
}

#msform {
	position: relative;
	padding: 12px 16px 8px;
}

.icon_dummy i {
	font-size: 70px;
	opacity: .4;
	padding: 20px;
}

.documentt_view tr td {
	border-bottom: 1px solid #d7d7d7;
}

table.request_table {
	border: 1px solid #cacbca;
}

.request-detail .form-card {
	width: 100%;
}

table.request_table tr {
	float: none;
	display: table-row;
	border-bottom: 1px solid #cacbca;
	width: auto;
}

table.request_table tr:last-child {
	border-bottom: none;
}

table.request_table td {
	display: table-cell;
	text-align: left;
	width: auto;
	float: none;
}

.request_table tr:first-child td,
.request_table tr td:first-child {
	background: #ebebeb;
	text-shadow: 0 0 BLACK;
}

.request_table tr:first-child td:first-child,
.request_table tr td:first-child {
	width: 150px;
}

.request_table .fa-times-circle {
	color: red;
}

.request_table .fa-check-circle {
	color: green;
}

.re-upload-wrapper .photo_icons {
	float: none;
	justify-content: flex-start;
	padding: 16px 0 0;
	margin-bottom: 0;
}

.img_re-upload-info label input {
	cursor: pointer;
}

.img_re-upload .img_upload {
	float: none;
	display: flex;
	border: 1px solid #cacbca;
	margin-bottom: 10px;
}

.img_re-upload-info {
	width: calc(100% - 25mm);
	padding: 10px 10px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	text-align: left;
}

.img_re-upload .user_pic img {
	width: 25mm;
}

.re-upload-info_top {
	display: flex;
	justify-content: space-between;
	text-align: left;
	align-items: flex-start;
	width: 100%;
	flex-wrap: wrap;
	padding-bottom: 10px;
}

.re_upload_box {
	width: 25%;
	padding: 0 10px 0 0;
}

.re-upload-info_btm .txt-indent {
	text-indent: 0;
}

.t_and_c.reupload-confirm {
	padding: 0 0 10px 0;
	height: auto;
	position: relative;
}

.reupload-confirm .alert {
	position: absolute;
	bottom: 0;
	left: 0;
}

.support_document table.re-upload-table tr td {
	height: auto;
}

.comments {
	width: 100%;
	text-align: left;
	color: #ffbf00;
	text-shadow: 0 0 #ffbf00;
	font-size: 0.81em;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 2px;
}

.comments span {
	background: #ffbf00;
	border-radius: 50%;
	font-family: serif;
	font-weight: 700;
	color: #fff;
	width: 16px;
	height: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.image_upload_container.image-upload-large .modal-body {
	height: 580px;
}

.comments p {
	width: calc(100% - 20px);
	line-height: 100%;
}

.applicant_details .comments span {
	display: flex;
}

.applicant_details .comments {
	display: flex;
}

.history_btn {
	background: #f96922;
	border: 1px solid #e3743d;
	color: #fff;
	border-radius: 3px;
	position: relative;
	min-width: 50px;
	width: auto;
	display: inline-block;
	padding: 6px 8px;
	text-align: center;
	position: relative;
	z-index: 99;
	width: 100px;
	height: 35px;
	cursor: pointer;
}

.form_photo_box .cropped {
	border: 1px solid #d2cbcb;
	width: 200px;
	height: 180px;
	object-fit: cover;
}

.user_pic {
	border-right: 1px solid #cacbca;
	display: flex;
	align-items: center;
	position: relative;
}

.user_pic .check {
	position: absolute;
	background: #0d7836;
	padding: 3px 5px;
	bottom: 0;
	right: 0;
	width: 25px;
	height: 25px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
}

.user_pic img {
	width: 35mm;
	height: auto;
	object-fit: fill;
}

.history_modal {
	display: flex;
	justify-content: space-between;
	height: 100%;
	padding: 0 0 10px;
}

.history_modal_info {
	width: 40%;
	min-width: 420px;
	padding: 0 10px 0 0;
}

.history_info-inner td {
	border-bottom: 1px solid #ddd;
}

.history_info-inner td:first-child {
	border-bottom: none;
}

.history_info-inner tr:last-child td {
	border-bottom: none;
}

.history_info_prv pdf-viewer {
	height: 100%;
}

.history_info_prv {
	width: calc(60% - 10px);
	height: 100%;
	border: 1px solid #cacbca;
}

.dashboard_links {
	color: #0087ff;
	text-decoration: underline;
}

span .dashboard_links:hover {
	color: #f96922 !important;
	text-decoration: underline;
}

.form-card .note {
	color: red;
	text-shadow: 0 0 red;
	text-transform: uppercase;
	padding-bottom: 8px;
}

.cod_alert {
	position: absolute;
	top: -4px;
}

.body_section {
	padding-bottom: 80px;
}

.mt-90 {
	margin-top: 90px;
}



/* 8 March end */
.eligible_box {
	width: 100%;
	float: left;
	padding-bottom: 60px;
}

.catgories h3,
.eligible_box h3 {
	font-size: 18px;
	padding-bottom: 12px;
}

.eligible_box input {
	float: right;
}

.cod-popup .main {
	width: 100%;
	padding: 10px 15px 0;
	float: left;
}

.cod-popup main {
	width: 100%;
	float: left;
	padding-bottom: 20px;
}

.catgories .save_btn {
	margin: 20px 0 !important;
}

.modal #msform {
	padding: 0;
}

/* ============Banner========== */
.banner_wrapper {
	padding-top: 76px;
}

.home_page:after {
	content: '';
	position: absolute;
	background: url('../../assets/images/home_bg_img.png');
	background-repeat: no-repeat;
	background-position: -90px 0px;
	top: 0;
	left: 0;
	width: 358px;
	height: 625px;
	z-index: -1;
}

.home_page:before {
	content: '';
	position: absolute;
	background: url('../../assets/images/home_bg_img-right.png');
	background-repeat: no-repeat;
	top: 120px;
	right: -60px;
	width: 358px;
	height: 625px;
	z-index: -1;
}

.home_banner_img {
	background: url('../../assets/images/home_banner.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	width: 100%;
	height: 320px;
	position: relative;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	text-align: center;
}

.home_banner_img:before {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
}

.banner_content {
	z-index: 1;
	padding: 0 10px;
}

/* ============Banner========== */
nav {
	width: 100%;
	height: 68px;
	float: left;
	background: #fff;
	padding: 5px 0;
	/* border-bottom: 1px solid #dfdfdf; */
	z-index: 1000;
	position: fixed;
	position: -webkit-fixed;
	top: 0;

}

.nav-links {
	list-style: none;
	width: 100%;
	text-transform: capitalize;
}

.nav-links li a {
	text-decoration: none;
}

.nav-links img {
	width: 19px;
}

.nav-links li a:hover {
	color: #F96922;
}

.nav-links li {
	position: relative;
}

.dash img {
	width: 14px;
	height: auto;
}

.nav-links li a:hover::before {
	width: 80%;
}

.adjust-height {
	min-height: 600px;
}

.header_box {
	display: inline-block;
}

.dropdown a span {
	max-width: 240px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	width: auto;
}

.fix_height {
	height: 140px;
}

.dropdown {
	width: auto;
	position: relative;
	margin-top: 0 !important;
}

ul li ul.dropdown-menu-top {
	width: 100%;
	background: #fff;
	display: none;
	position: absolute;
	z-index: 999;
	text-align: center;
	border: 1px solid #0D7836;
	float: left;
	/* box-shadow: 0 14px 28px rgb(0 0 0/9%),0 10px 10px rgb(0 0 0/9%); */
}

ul li ul.dropdown-menu-top li {
	display: block;
	margin: 3px 0 !important;
}

.header_box span {
	position: relative;
	top: -20px;
	padding-left: 9px;
	font-size: 13px;
	font-family: "sfui-semibold";
}

.header_box img {
	width: 177px;
	height: 49px;
}

hr.header_line {
	width: 0;
	margin: 0 auto;
	color: #CACBCA;
	border-width: 0;
	background-color: #CACBCA;
	display: inline-block;
	height: 50px;
	border-left: 2px dotted #fff;
}

.email-label {
	width: 100%;
	float: left;
	padding: 5px 0 13px;
}

.email-label label {
	width: auto;
	font-size: 13px;
}

.email-label span {
	width: auto;
	float: left;
	line-height: normal;
	padding-left: 3px;
}

.profile_type .form_box {
	height: 65px;
}

.profile_type .grid-two-box {
	padding: 0 !important;
}

.dropdown-menu-top li:after {
	content: "";
	display: block;
	height: 1px;
	width: 100%;
	margin: 2px 0;
	background: #0d7836;
}

.dropdown .custom_btn {
	margin: 10px 0 0 0;
	padding: 6px 6px !important;
	font-family: "sfui-semibold";
	white-space: nowrap;
}

button.mat-menu-btn.mat-menu-item {
	line-height: 37px;
	border-bottom: 1px solid #dfdfdf;
	margin-top: 0;
	padding: 0 21px;
}

button.mat-menu-btn.mat-menu-item:last-child {
	border-bottom: none;
}

button.mat-menu-item.mat-logout {
	line-height: 16px;
	margin-top: 15px;
}

.cdk-overlay-pane .mat-menu-panel .mat-menu-content:not(:empty) {
	padding-top: 0;
	padding-bottom: 0;
}

button.mat-menu-item.mat-logout .mat-menu-panel {
	min-width: 147px;
	min-height: 48px;
}

.dropdown-menu-top li:last-child:after {
	background: none;
	margin: 2px 0;
}

.cdk-overlay-pane .mat-menu-panel {
	min-width: initial;
	min-height: 45px;
	max-width: none;
}

a.mat-menu-trigger.custom_btn i {
	padding-left: 7px;
}

.custom_btn1 {
	background: #0d7836;
	color: #fff;
	padding: 5px 8px;
	border-radius: 2px;
	width: auto;
	display: block;
	border: none;
	border-radius: 3px;
	min-width: 70px;
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
}

.ms_form_img {
	height: calc(100% - 45px);
}

.custom_btn,
.btn {
	background: #0D7836;
	padding: 6px 12px !important;
	color: #fff !important;
	border-radius: 3px;
	width: auto !important;
	border: 1px solid #0D7836;
	cursor: pointer;
	/* margin: 10px 5px 10px 0; */
	/* margin: 20px 5px; */
	margin: 10px 0px;
	min-width: 70px;
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	font-size: 14px;
}

ul.align-left-ins li {
	text-align: left;
}

.modal-footer .custom_btn,
.modal-footer .btn {
	margin: 0 0;
}

.modal-footer .back_btn {
	margin: 0 0 0 5px;
}

.custom_btn:hover,
.btn:hover {
	background: #10572B;
	color: #FFF;
}

.back_btn {
	padding: 6px 12px !important;
	cursor: pointer;
	width: auto !important;
	background: #636363;
	color: #f5f5f5;
	border-radius: 3px;
	border: 1px solid #636363;
	margin: 10px 5px 0 0;
	min-width: 70px;
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	font-size: 14px;
}

.back_btn:hover {
	background: #686868;
	color: #fff;
}

.save_btn {
	background: #F96922;
	padding: 7px 12px !important;
	color: #fff;
	cursor: pointer;
	border-radius: 3px;
	width: auto !important;
	border: 1px solid #F96922;
	margin: 5px 5px 10px 0;
	/* margin: 15px 0px 10px; */
	min-width: 70px;
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
}

.save_btn:hover {
	background: #DF692F;
	color: #fff;
}

.mright5 {
	margin-right: 5px;
}

.mtb-10 {
	margin: 10px 0;
}

.toast-close-button {
	margin-top: 4px;
}

.edit_btn {
	background: #E8BA4E;
	padding: 7px 12px !important;
	color: #fff;
	cursor: pointer;
	border-radius: 3px;
	width: auto !important;
	border: 1px solid #DFB146;
	margin: 15px 5px 10px 0;
	min-width: 70px;
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
}

.custom_btn.submit-pay {
	margin: 10px 5px 10px 0;
}

.edit_btn:hover {
	background: #F0B11C;
	color: #fff;
}

.receipt_btn {
	background: #ff8d00;
	padding: 7px 12px !important;
	color: #fff;
	cursor: pointer;
	border-radius: 3px;
	width: auto !important;
	border: 1px solid #DFB146;
	margin-right: 10px;
	margin: 10px 5px;
	border-color: #d77a07;
	min-width: 70px;
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
}

.receipt_btn:hover {
	background: #BD880B;
	color: #fff;
}

.right_legend {
	position: relative;
	width: 100%;
	float: left;
}

.white-nowrap {
	white-space: nowrap;
}

.main {
	width: 90%;
	padding-right: 45px;
	padding-left: 45px;
	margin-right: auto;
	margin-left: auto;
	position: relative;

}

.main h2 {
	padding-bottom: 0;
}


.main1 {
	width: 90%;
	padding-right: 44px;
	padding-left: 44px;
	margin-right: auto;
	margin-left: auto;
	margin-bottom: 30px;
	padding-bottom: 30px;
	position: relative;
	top: 72px;
}

.wrapper {
	width: 100%;
	float: left;
	position: relative;
	top: 80px;
	padding-bottom: 40px;
}

.g-w .wrapper {
	display: flex;
	position: relative;
	top: 0;
}

.tabs_vertical.g-w .wrapper {
	border: 1px solid #dbdbdb;
}

:focus {
	outline: none;
}

.verify-table td {
	color: #434343;
}


.home h4 {
	padding-bottom: 15px;
	color: #0d7836;
}

.instruction_box ul li {
	list-style-type: disc;
	text-align: left;
	margin-left: 16px;
	padding-top: 3px;
}

.form_main fieldset {
	background: white;
	border: 1px solid #cacbca;
	box-sizing: border-box;
	width: 100%;
	margin-bottom: 90px;
	position: relative;
	text-align: left;
	padding: 0;
	/* display: none */
}

fieldset.plr10 {
	padding: 5px 15px 15px;
	margin-bottom: 10px;
}

.step_info {
	background: #ebebeb;
	padding: 5px 13px 2px;
	border-bottom: 1px solid #cacbca;
}

.resend a {
	color: #0073EE;
	text-decoration: underline;
}

.form_main {
	width: 100%;
	float: left;
}

.form-card {
	text-align: left
}

#progressbar {
	width: 100%;
	float: left;
	color: lightgrey;
	margin-bottom: 10px;
}

#progressbar .active {
	color: #515151;
	font-family: "sfui-semibold";
}

#progressbar li.sevenstages {
	width: 14.28% !important;
}

#progressbar li {
	list-style-type: none;
	font-size: 14px;
	width: 16.66%;
	float: left;
	position: relative;
	font-weight: 400;
	text-align: center;
}

#progressbar #passport-type:before {
	content: "1";
}

#progressbar #passport-type-tick:before {
	content: '\2713';
	border: 1px solid #0d7836;
	background: #f0fff5;
	color: #0d7836;
	font-size: 20px;
}

#progressbar #passport-type-tick p {
	font-family: "sfui-regular";
}

#progressbar #photo-upload:before {
	content: "2"
}

#progressbar #photo-upload-tick:before {
	content: '\2713';
	border: 1px solid #0d7836;
	background: #f0fff5;
	color: #0d7836;
	font-size: 20px;
}

#progressbar #photo-upload-tick p {
	font-family: "sfui-regular";
}

#progressbar #personal:before {
	content: "3"
}

#progressbar #personal-tick:before {
	content: '\2713';
	border: 1px solid #0d7836;
	background: #f0fff5;
	color: #0d7836;
	font-size: 20px;
}

#progressbar #personal-tick p {
	font-family: "sfui-regular";
}

#progressbar #contact:before {
	content: "4";
}

#progressbar #contact-tick:before {
	content: '\2713';
	border: 1px solid #0d7836;
	background: #f0fff5;
	color: #0d7836;
	font-size: 20px;
}

#progressbar #contact-tick p {
	font-family: "sfui-regular";
}

#progressbar #kin:before {
	content: "5";
}

#progressbar #kin-tick:before {
	content: '\2713';
	border: 1px solid #0d7836;
	background: #f0fff5;
	color: #0d7836;
	font-size: 20px;
}

#progressbar #kin-tick p {
	font-family: "sfui-regular";
}

/* #progressbar #processing-center:before {
	content: "5";
}

#progressbar #processing-center-tick:before {
	content: '\2713';
	border: 1px solid #0d7836;
	background: #f0fff5;
	color: #0d7836;
	font-size: 20px;
}

#progressbar #processing-center-tick p {
	font-family: "sfui-regular";
} */

#progressbar #document-upload:before {
	content: "6"
}

#progressbar #document-upload-tick:before {
	content: '\2713';
	border: 1px solid #0d7836;
	background: #f0fff5;
	color: #0d7836;
	font-size: 20px;
}

#progressbar #document-upload-tick p {
	font-family: "sfui-regular";
}

#progressbar #passport-delivery:before {
	content: "7"
}

#progressbar #passport-delivery-tick:before {
	content: '\2713';
	border: 1px solid #0d7836;
	background: #f0fff5;
	color: #0d7836;
	font-size: 20px;
}

#progressbar #passport-delivery-tick p {
	font-family: "sfui-regular";
}


#progressbar li:before {
	width: 30px;
	height: 30px;
	line-height: 30px;
	display: block;
	font-size: 15px;
	/* color: #0d7836; */
	background: #fff;
	border-radius: 50%;
	margin: 7px auto 2px auto;
	padding: 2px;
	border: 1px solid rgb(13 120 54 / 47%);
	font-family: "sfui-semibold";
	text-align: center;
}

#progressbar li:after {
	content: '';
	width: 100%;
	height: 5px;
	background: rgb(13 120 54 / 47%);
	position: absolute;
	left: 0;
	top: 25px;
	z-index: -1
}

#progressbar li.active::before {
	border: 1px solid #0d7836;
	background: #097734;
	color: #fff;
}

#progressbar li.active:after {
	background: #0d7836
}

label {
	width: 100%;
	text-align: left;
	font-size: 13px;
	font-family: "sfui-semibold";
}

.medatory {
	color: #ee6969;
}

input,
select,
textarea {
	width: 100%;
	border: 1px solid #cacbca;
	height: 36px;
	padding: 0 10px;
	box-sizing: border-box;
	font-size: 14px;
}

.textbox {
	width: 100%;
	float: left;
	height: 150px;
}

.info {
	padding-bottom: 10px;
	width: 100%;
	float: left;
}

select {
	appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	border-radius: 0;
	background: #fff;
}

.icon_caret {
	width: auto;
	float: right;
	position: absolute;
	right: 7px;
	top: 6px;
	color: rgba(0, 0, 0, .54);
}

.header-session .icon i {
	padding-right: 5px;
}

.mat-select {
	height: 36px;
}

.input_box {
	position: relative;
	width: 100%;
	float: left;
	text-align: left;
}

.instruction_box {
	border: 1px solid #cacbca;
	padding: 5px 20px 10px;
	text-align: justify;
	margin-top: 5px;
}

.instruction_box h4 {
	font-size: 20px;
	color: #0d7836;
}

.fee span {
	width: 100%;
	float: left;
	text-align: center;
}

.focus_border:focus {
	border: 2px solid #4caf50;
	transition: 0.1s;
}

.focus_border {
	color: #212121 !important;
}

.effect {
	border: 1px solid #cacbca;
	padding: 7px 4px 9px;
	transition: 0.4s;
	color: #434343 !important;
}

.effect~.focus-border:before,
.effect~.focus-border:after {
	content: "";
	position: absolute;
	top: 0;
	left: 50%;
	width: 0;
	height: 2px;
	background-color: #4caf50;
	transition: 0.4s;
}

.effect~.focus-border:after {
	top: auto;
	bottom: 0;
}

.effect~.focus-border i:before,
.effect~.focus-border i:after {
	content: "";
	position: absolute;
	top: 50%;
	left: 0;
	width: 2px;
	height: 0;
	background-color: #4caf50;
	transition: 0.6s;
}

.effect~.focus-border i:after {
	left: auto;
	right: 0;
}

.effect:focus~.focus-border:before,
.effect:focus~.focus-border:after {
	left: 0;
	width: 100%;
	transition: 0.4s;
}

.effect:focus~.focus-border i:before,
.effect:focus~.focus-border i:after {
	top: 0;
	height: 100%;
	transition: 0.6s;
}

footer {
	background: #ebebeb;
	width: 100%;
	float: left;
	position: fixed;
	bottom: 0;
	left: 0;
	z-index: 999;
	/* padding-top: 5px; */
	border-top: 1px solid #d2d2d2;
}

.footer p {
	text-align: left;
}

.view-buttons {
	width: 100%;
	float: left;
	margin-bottom: 60px;
}

.img_upload {
	height: auto;
	width: auto;
	object-fit: cover;
	float: left;
	text-align: center;
}

/* .img_box {
	width: 100%;
	float: left;
} */

.img_upload label {
	width: 100%;
	float: left;
}

span.icon_image {
	position: relative;
}

span.icon_image i {
	border: 1px solid #cacbca;
	border-radius: 5px;
	width: 30px;
	height: 27px;
	padding: 7px;
	float: left;
	position: absolute;
	right: 6px;
	background: #fff;
	top: -29px;
	text-align: center;
}

.mob_legends {
	display: flex;
	justify-content: flex-start;
	margin: 5px 0;
}

input.preview_btn {
	width: auto;
	padding: 6px 10px;
	height: auto;
	border: 1px solid #0d7836;
	border-radius: 3px;
	background: #0d7836;
	color: #fff;
	line-height: 14px;
	margin-right: 8px;
	cursor: pointer;
	min-width: 70px;
}

.new_app {
	position: relative;
	width: 100%;
	text-align: right;
	display: flex;
	padding: 0px 0px 10px 0;
	justify-content: space-between;
	align-items: center;
}

.new_app_dropdown {
	position: relative;
	display: inline-block;
}

.new_app .custom_btn {
	display: inline-block;
}

.new_app_dropdown ul {
	position: absolute;
	right: 3px;
	top: 100%;
	overflow: hidden;
	display: none;
}

.new_app_dropdown ul.active {
	display: block;
}

.new_app_dropdown ul li {
	width: 100%;
}

.new_app .new_app_dropdown ul input {
	margin: 0 3px 0 0;
	width: 100% !important;
	border-radius: 0;
	text-align: left;
}

.new_app_dropdown ul li input {
	width: 100%;
}

/* .alert-danger {
	width: 100%;
	float: left;
	text-align: left;
	color: red;
	font-size: 13px;
	line-height: 10px;
	padding-top: 3px;
} */

.alert {
	width: 100%;
	float: left;
	text-align: left;
	color: red;
	font-size: 10px;
	line-height: 100%;
	padding-top: 3px;
}

.note-alert {
	border: 1px solid red;
	padding: 0 5px;
	margin: 5px 0 15px;
}

.note-alert .alert {
	float: none;
	line-height: 18px;
	display: inline-block;
	font-size: 12px;
}

.form_box {
	width: 100%;
	height: 60px;
	font-size: 15px;
}

.banner-img img {
	width: 100%;
}

.ptb20 {
	padding: 10px 0 15px;
}

.personal.detail h3 {
	font-size: 14px;
	line-height: 19px;
	font-size-adjust: 0.6;
}

.ques_box {
	padding: 10px 0;
}

.ques_box input {
	width: auto;
	display: inline-block;
	height: auto;
}

.ques_box label {
	width: auto;
	padding-right: 11px;
}

.center_body h6 {
	text-align: left;
	font-size: 16px;
	padding: 10px 0 1px;
}

table {
	width: 100%;
	border: 1px solid #cacbca;
	border-collapse: collapse;
	border-spacing: 0;
	overflow-y: auto;
	overflow-x: hidden;
	/* table-layout: fixed; */
}

.upload-support-doc-table {
	table-layout: auto;
	margin-bottom: 10px;
}

tbody {
	height: auto;
	overflow-y: auto;
	max-height: 360px;
}

thead {
	width: 100%;
	position: sticky;
	top: 0;
}

tr {
	border-bottom: 1px solid #cacbca;
}

th {
	padding: 0 5px 0;
	color: rgb(10, 90, 30);
	background: #d7eaca;
	font-family: "sfui-semibold";
	height: 36px;
	text-align: left;
	font-weight: normal;

	border-bottom: 1px solid #cacbca;
}

td {
	padding: 0 5px;
	height: 36px;
	text-align: left;
	border-left: 1px solid #ddd;
	text-overflow: ellipsis;
	overflow: hidden;

}

table tr:last-child {
	border-bottom: 0;
}

.dash th,
.dash td {
	white-space: nowrap;
}

table input[type="checkbox"] {
	width: auto;
	height: auto;
	padding: 5px !important;
}

textarea {
	width: 100%;
	height: 111px;
	resize: none;
	font-family: "sfui-regular";
	font-size: 14px;
	float: left;
}

.textbox_profile {
	margin: 12px 0 55px;
	width: 100%;
	float: left;
}

.input_profile {
	margin: 12px 0 0;
	width: 100%;
	float: left;
}

.p-info fieldset {
	background: #f7f7f7;
	margin-bottom: 30px;
}

.p-info {
	width: 100%;
	float: left;
}

.ngx-dialog-header h4 {
	font-weight: 500 !important;
}

legend {
	padding: 0px 3px;
	color: #0d7836;
	font-family: "sfui-semibold";
	font-size: 14px;
	text-align: left;
}

.t_and_c {
	position: relative;
	margin-top: 5px;
	display: flex;
	font-family: "sfui-regular";
	width: fit-content;
	float: none;
	padding: 0 0 6px;
}

.t_and_c .alert {
	position: absolute;
	left: 0;
	bottom: -3px;
}

.t_and_c input {
	width: 13px;
	height: 13px;
	margin-top: 3px;
}

.t_and_c label {
	font-family: "sfui-regular";
}

.cancel-btn {
	background: #ddd;
	border: 1px solid #ddd;
	color: #8a8787;
	min-width: 70px;
}

i {
	cursor: pointer;
}

.cancel-btn:hover {
	background: #fff;
}


/* Login Page CSS Start */

.home {
	width: 100%;
	float: left;
}


.banner_text {
	width: 100%;
	float: left;
	color: #fff;
	position: absolute;
	top: 35%;
	left: 8%;
	line-height: 42px;
}

.banner_text hr {
	border: 1px solid #f96922;
	width: 40px;
	margin: 10px 0;
}

.top_head img {
	width: 38px;
	height: auto;
	display: inline-block;
}

.top_head {
	background: #F96922;
	padding: 5px 18px;
	text-align: left;
	margin-top: -60px;
	position: relative;
}

.main-bottom {
	background: #F5F5F5;
	width: 100%;
	text-align: center;
	margin-bottom: 40px;
	height: 120px;
	position: relative;
	box-shadow: 0 14px 28px rgb(0 0 0 / 9%), 0 10px 10px rgb(0 0 0 / 9%);
}

.top_head span {
	width: auto;
	position: relative;
	display: inline-block;
	color: #fff;
	font-family: "sfui-semibold";
	left: 10px;
}

.top_head div {
	width: 100%;
	width: 100%;
	float: left;
	position: absolute;
	top: 28px;
	left: 26%;
	color: #fff;
	font-family: "sfui-semibold";
}

button {
	padding: 7px 20px;
	margin-top: 10px;
	background: #0D7836;
	border: none;
	position: relative;
	color: #fff;
	font-family: 'sfui-semibold';
	border-radius: 3px;
	font-size: 13px;
}

button img {
	width: 18px;
	position: absolute;
	left: 16px;
	top: 8px;
}

.main-bottom p {
	text-align: justify;
	padding: 10px 20px 0;
}

.img_num img {
	float: right;
	position: absolute;
	right: 0;
	bottom: 0;
}

.loginBtn {
	cursor: pointer;
}

.dash.shop-cart i {
	cursor: default;
}


/* Login Page CSS End */

.disable {
	cursor: not-allowed;
	background: #ccc !important;
	border: 1px solid #ccc;
	color: #9d9d9d;
}

.dropdown-menu-top a:hover {
	color: #000 !important;
}

input:disabled,
select:disabled {
	background-color: #e6e6e6 !important;
	cursor: not-allowed;
	border: 1px solid #b9b9b9;
	color: #8e8e8e !important;
	opacity: 0.7;
}


/* ........ DASHBOARD ............  */

.bottom_span {
	width: 100%;
	float: left;
	border-bottom: 1px solid #f3f3f3;
	padding-bottom: 10px;
}

table.dash {
	margin-bottom: 0;
	height: 160px;
	margin-top: 20px;
}

.dash th {
	background: #0D7836;
	color: #fff;
	position: sticky;
	position: -webkit-sticky;
	top: 0;
	z-index: 4;
	font-weight: normal;
}

tr.cus_row td {
	width: 50%;
	text-align: center;
	border: none;
}

.cus_row {
	margin: 20px 0;
}

.info-covid {
	background: #FBD0D0;
	color: #AC3939;
	padding: 6px 13px;
	width: 100%;
	font-size: 11px;
	/* letter-spacing: 1px; */
	margin-bottom: 16px;
	text-transform: capitalize;
}

.banner-img-name {
	width: 100%;
	background: #0d7836;
	color: #fff;
	text-align: left;
	padding: 4px 0;
	/* margin: 72px 0 7px; */
	/* position: relative;
	top: 72px; */
}

.banner-img-name h2 {
	font-size: 16px;
	font-weight: normal;
}

.user_type,
.profile_type {
	text-align: center;
	margin: 0 auto;
	width: 50%;
}

.line_hr {
	width: 90%;
	margin: 0 auto;
	color: #CACBCA;
	height: 1px;
	border-width: 0;
	background-color: #CACBCA;
	display: inline-block;
}

.profile_type .custom_btn {
	margin: 10px 0;
}

.user_type ul li {
	display: block;
	position: relative;
	float: left;
	width: 100%;
}

.user_type ul li input[type=radio] {
	position: absolute;
	visibility: hidden;
}

.user_type ul li label {
	font-size: 14px;
	font-family: "sfui-regular";
	display: block;
	position: relative;
	padding: 14px 30px 5px;
	z-index: 9;
	cursor: pointer;
	-webkit-transition: all 0.25s linear;
}

.user_type ul li .check {
	display: block;
	position: absolute;
	border: 1px solid #CACBCA;
	border-radius: 100%;
	height: 15px;
	width: 15px;
	top: 30px;
	left: 0;
	z-index: 5;
	transition: border .25s linear;
	-webkit-transition: border .25s linear;
}

.user_type ul li .check::before {
	display: block;
	position: absolute;
	content: '';
	border-radius: 100%;
	height: 7px;
	width: 7px;
	top: 3px;
	left: 3px;
	margin: auto;
	transition: background 0.25s linear;
	-webkit-transition: background 0.25s linear;
}

.user_type input[type=radio]:checked~.check {
	border: 1px solid #0D7836;
}

.user_type input[type=radio]:checked~.check::before {
	background: #0D7836;
}

.user_type input[type=radio]:checked~label {
	color: #0D7836;
}

.user_type span {
	width: 100%;
	float: left;
	font-size: 13px;
}

.tabset #tab1 {
	position: absolute;
	left: -100vw;
}

.tabset #tab2 {
	position: absolute;
	left: -150vw;
}

.tabset #tab3 {
	position: absolute;
	left: -90vw;
}

.tabset>input:first-child:checked~.tab-panels>.tab-panel:first-child,
.tabset>input:nth-child(3):checked~.tab-panels>.tab-panel:nth-child(2),
.tabset>input:nth-child(5):checked~.tab-panels>.tab-panel:nth-child(3),
.tabset>input:nth-child(7):checked~.tab-panels>.tab-panel:nth-child(4),
.tabset>input:nth-child(9):checked~.tab-panels>.tab-panel:nth-child(5),
.tabset>input:nth-child(11):checked~.tab-panels>.tab-panel:nth-child(6) {
	display: block;
}

.tabset>label {
	position: relative;
	display: inline-block;
	padding: 10px 10px;
	cursor: pointer;
	width: auto;
	float: none;
	font-size: 14px;
	color: #0000005d;
	border-top: 1px solid #bdc4c0;
	border-right: 1px solid #bdc4c0;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 131px;
}

.tabset {
	width: 100%;
	float: left;
	display: flex;
	flex-wrap: wrap;
	position: relative;
	top: 0px;
}

.tabset>label:hover,
.tabset>input:focus+label {
	color: #0d7836;
}

.tabset>label:hover::after,
.tabset>input:focus+label::after,
.tabset>input:checked+label::after {
	background: #0d7836;
}

.tab-panel p {
	display: inline-block;
	padding-left: 20px;
}

.tabset>input:checked+label {
	border-color: #0d7836;
	border-bottom-color: rgb(13, 120, 54);
	border-bottom: 1px solid #0d7836;
	margin-bottom: 0;
	background: #0d7836;
	color: rgb(255, 255, 255);
}

.tab-panels {
	/* border-top: 1px solid #c3c8c5; */
	width: 100%;
}

.tab-panel table {
	border-top: 0;
}

.step_info span {
	font-size: 16px;
	font-family: 'sfui-semibold';
}

.m40 {
	margin-bottom: 40px;
}

.w100 {
	width: 100%;
	float: left;
}

.mb15 {
	margin-bottom: 15px;
}

.search_grid {
	display: grid;
	grid-template-columns: 33% 2% 62%;
	grid-gap: 20px;
}

.profile_view ul {
	display: grid;
	align-items: center;
	grid-template-columns: 25% 25% 25% 24%;
	grid-gap: 8px;
	margin-bottom: 10px;
}

.profile_view li {
	padding-left: 45px;
}

.profile_view li:first-child {
	padding-left: 0;
}

.big_font {
	font-size: 20px;
}

.status_info img {
	width: 175px;
	text-align: right;
	float: right;
}

.grid_detail {
	display: grid;
	grid-gap: 20px;
	grid-template-columns: 49% 49%;
}

.grid_detail-box {
	padding: 0 10px;
}

.profile_view p {
	display: inline-block;
}

.fr {
	float: right;
}

.fr1 {
	float: right;
}

.grid_detail-box:first-child {
	border-right: 1px solid #9d9d9d;
}

.row_box {
	padding: 6px 0 0;
}

.total_box p {
	font-size: 19px;
}

.total_box label,
.total_box span {
	font-size: 15px;
}

.catagories_type {
	display: inline-block;
}

.catagories_type:last-child {
	padding-left: 50px;
}

.main-footer {
	width: 90%;
	padding-right: 44px;
	padding-left: 44px;
	margin-right: auto;
	margin-left: auto;
}

fieldset {
	border: 1px solid #9d9d9d;
	/* padding: 0px 20px; */
	margin: 15px 0 0;
}

.profile_view fieldset {
	padding: 0 20px 10px;
}

.notes {
	margin: 18px 0;
}

.payment_detail .grid-two-box {
	padding: 0;
}

.pd0 {
	padding: 0 !important;
}

.pd0 legend {
	margin: 0px 20px;
}

.pd12 {
	padding: 5px 20px 1px;
}



.modal-content {
	max-width: 800px !important;
	width: 100%;
	height: auto;
	background: #fff;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	margin: 0 auto;
	text-align: left;
	border-radius: 3px;
	z-index: 1002;
}

.tl {
	text-align: left !important;
	margin-left: 22px;
}

.notice_modal {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: left;
	padding: 0 !important;
	background: #0d7836;
	padding: 15px;
	border-radius: 6px;
	width: 100%;
	max-width: 35% !important;
	overflow: hidden;
}

.notice_modal .modal-body,
.notice_modal .modal-footer {
	background: #fff;
}

.notice_modal .modal-footer {
	justify-content: space-between;
}


/* payment page css  */

.grid-payment-box h4 {
	background: #f96922;
	color: #fff;
	padding: 5px 10px;
	border: 1px solid #f96922;
	border-top-left-radius: 2px;
	border-top-right-radius: 2px;
}

.pd20 {
	padding: 10px 10px;
}

.personal.detail span {
	width: 100%;
	float: left;
	font-size: 12px;
	line-height: 18px;
	padding-left: 16px;
	font-family: 'sfui-semibold';
}

.personal.detail {
	float: left;
	width: 75%;
}

.personal.detail i {
	float: left;
	width: 15px;
	padding-top: 2px;
	color: red;
}

.fee_detail {
	width: 25%;
	float: left;
	text-align: right;
}

.total_fee {
	position: absolute;
	bottom: 0;
	width: 100%;
	float: left;
	padding: 4px 10px;
	background: #0d7836;
	color: #fff;
}

.cart-info {
	width: 100%;
	float: left;
	position: relative;
	height: 370px;
	overflow-y: auto;
	box-shadow: 0 1px 4px #f4f4f4;
}

.grid-payment-box h5 {
	font-size: 17px;
}

.fee {
	padding: 10px;
	border: 1px solid #f7f7f7;
	width: 100%;
	float: left;
	background: #f9f9f9;
	border: 1px solid #0d7836;
}

.fee div {
	width: 100%;
	float: left;
	display: block;
	text-align: left;
	border-bottom: 1px solid #eeebeb;
	padding: 6px 0;
}

.pay_input input {
	padding: 3px 0;
}

.head_table {
	width: auto;
	float: left;
	text-align: left;
	font-size: 18px;
	margin-bottom: 10px;
}

.change_btn {
	margin: 0;
	float: right;
}

.fee span {
	width: auto;
	float: right;
	text-align: center;
	display: block;
}

.center_body {
	text-align: center;
	padding: 40px 0;
	width: 100%;
	float: left;
	margin-bottom: 50px;
}

.card h1 {
	color: #0d7836;
	font-family: "sfui-semibold";
	font-size: 25px;
	margin-bottom: 10px;
}

.card p {
	color: #404F5E;
	font-size: 16px;
	margin: 20px 0;
	text-align: left;
	font-weight: bold;
}

.card i {
	color: #0d7836;
	font-size: 60px;
	line-height: 100px;
	margin-left: -10px
}

.card {
	background: white;
	padding: 20px;
	border-radius: 4px;
	box-shadow: 0px 2px 8px #C8D0D8;
	display: inline-block;
	margin: 0 auto;
	width: 900px;
}

.card table {
	border: 1px solid #aeaeae;
}

.card table tr td i {
	color: #000;
	padding-left: 11px;
}

.info_paid {
	border: 1px solid #ddd;
	width: 100%;
	margin-bottom: 20px;
	float: left;
}

.info_paid span {
	width: 100%;
	float: left;
	text-align: right;
	padding: 6px;
	border-bottom: 1px solid #ddd;
}

.info_paid span:last-child {
	border-bottom: 0;
}

.info_paid span b {
	text-align: right;
	width: auto;
	float: left;
}

.cardimg {
	position: absolute;
	width: 40px;
	top: 10px;
	right: 7px;
}

.modal {
	z-index: 9999 !important;
	background: rgba(0, 0, 0, .7) !important;
}

.modal table {
	margin: 0 !important;
	height: auto;
	width: auto !important;
}

.confirmbox-container {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	min-width: 500px;
	padding: 0 !important;
	background: #fff;
	padding: 15px;
	border-radius: 6px;
}

.confirmbox-container h3 {
	padding: 6px 10px 5px !important;
	margin: 10px 0 !important;
	border-bottom: 1px solid #0d7836 !important;
}

.confirmbox-container p {
	margin: 0 !important;
	padding: 5px !important;
}



.btn-grp button {
	margin: 0 7px 20px !important;
}

.card table i {
	font-size: 16px;
	line-height: normal;
}

.card table {
	height: auto;
}

.filter_box {
	width: 20%;
	float: left;
	margin-right: 10px;
}

.change_request {
	width: 100%;
	float: left;
	background: #f4f4f4;
	padding: 10px;
	margin-bottom: 20px;
}

.change_table {
	height: auto;
}

.change_request .save_btn {
	margin: 18px 0 0;
	width: 95px !important;
}

.detail_change_view {
	padding: 10px;
}

.detail_change_view table {
	margin-bottom: 0;
}

.field__input:focus::-webkit-input-placeholder,
.field__input:focus::-moz-placeholder {
	color: var(--uiFieldPlaceholderColor);
}

.field {
	border-width: 1px;
	display: inline-flex;
	position: relative;
	font-size: 1rem;
}

.field__input {
	height: 38px;
	padding: 0.25rem 1rem 0.15em;
	--uiFieldPlaceholderColor: var(--fieldPlaceholderColor, #767676);
}

.field__input::-webkit-input-placeholder {
	opacity: 0;
	transition: opacity .2s ease-out;
}

.field__input::-moz-placeholder {
	opacity: 0;
	transition: opacity .2s ease-out;
}

.field__input:focus::-webkit-input-placeholder {
	opacity: 1;
	transition-delay: .2s;
}

.field__input:focus::-moz-placeholder {
	opacity: 1;
	transition-delay: .2s;
}

.field__label-wrap {
	box-sizing: border-box;
	pointer-events: none;
	cursor: text;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.field__label-wrap::after {
	content: "";
	box-sizing: border-box;
	width: 100%;
	height: 0;
	opacity: 0;
	position: absolute;
	bottom: 0;
	left: 0;
}

.field__input:focus~.field__label-wrap::after {
	opacity: 1;
}

.field__label {
	position: absolute;
	left: var(--uiFieldPaddingLeft);
	top: calc(50% - .5em);
	padding: 0 1rem;
	line-height: 1;
	font-size: 14px;
}

.field__input:focus~.field__label-wrap .field__label,
.field__input:not(:placeholder-shown)~.field__label-wrap .field__label {
	font-size: 11px !important;
	top: var(--fieldHintTopHover, .25rem);
}

.dashbox {
	width: 100%;
	float: left;
}

.box_dashboard {
	border: 1px solid #0d7836;
	padding: 8px 10px;
	width: 100%;
	float: left;
	height: 100px;
}

.box_dashboard img {
	display: block;
	width: 60px;
	float: left;
}

.box_dashboard:hover {
	background: #f1fbeb;
	color: #0d7836;
}

.box_dashboard a {
	width: 100%;
	float: left;
}

.upload_icon {
	padding-top: 16px;
}

.dash_info {
	width: auto;
	padding: 5px 12px;
	text-align: left;
	margin-left: 10px;
	display: table;
}

.important_instructions {
	width: 100%;
	float: left;
	border-top: 1px solid #cacbca;
	margin: 30px 0;
}

.important_instructions li {
	list-style-type: disc;
	list-style-position: inside;
}

.important_instructions h3 {
	margin: 10px 0;
}

.dropdown-toggle::after {
	display: inline-block;
	width: 0;
	height: 0;
	margin-left: 0.255em;
	vertical-align: 0.255em;
	content: "";
	border-top: 0.3em solid;
	border-right: 0.3em solid transparent;
	border-bottom: 0;
	border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
	margin-left: 0;
}

.phone_input {
	height: 36px;
	width: 100%;
	float: left;
}

#phone {
	height: 20px;
	border: 0 !important;
	padding-left: 88px !important;
}

.iti__selected-flag {
	width: 80px !important;
}

.grid-history {
	display: grid;
	grid-gap: 15px;
	grid-template-columns: 40% 60%;
	width: 100%;
	float: left;
}

.history_box {
	border: 1px solid #cacbca;
	width: 100%;
	float: left;
}

.history_box1 {
	border: 1px solid #cacbca;
	border-bottom: 0;
	width: 100%;
	float: left;
}

.green-band {
	background: #d7eaca;
	padding: 8px 8px 5px;
}

.history_box ul li {
	padding: 7px 10px;
	border-bottom: 1px solid #ddd;
}

.history_box ul li span {
	display: inline-block;
}

span.align-right {
	float: right;
}

.request_counts {
	margin-bottom: 18px;
}

.request_counts ul li {
	display: inline-block;
	width: 24%;
	border: 0;
	background: #fff;
	box-shadow: 0 0px 10px rgba(0, 0, 0, 0.09), 0 5px 6px rgba(0, 0, 0, 0.09);
	margin-right: 10px;
	padding: 15px 12px 10px;
}

.request_counts ul li:last-child {
	margin-right: 0;
}

.history_box1 table tr:last-child {
	border-bottom: 1px solid #cacbca !important;
}

.request_counts h4 {
	font-size: 18px;
	text-align: center;
}

.blue {
	background: #AFDFF5;
	width: 30px;
	height: 30px;
	color: white;
	border: 1px solid #A0B6E3;
	border-radius: 50%;
}

.lightgreen {
	background: #91E8B2;
	width: 30px;
	height: 30px;
	color: white;
	border: 1px solid #69CB8E;
	border-radius: 50%;
}

.lightorange {
	background: #EBD89B;
	width: 30px;
	height: 30px;
	color: white;
	border: 1px solid #E6C148;
	border-radius: 50%;
}

.re-red {
	background: #F88490;
	width: 30px;
	height: 30px;
	color: white;
	border: 1px solid #F88499;
	border-radius: 50%;
}

.history_box h4 {
	color: #0d7836;
}

.history_box1 table th {
	background: #f5f5f5;
	border-top: 1px solid #d5d5d5;
	border-bottom: 1px solid #d5d5d5;
	color: #353434;
	font-size: 12px;
}

.history_box1 table {
	width: 100%;
	border: 0;
	margin: 0;
}

.history_box .save_btn {
	float: right;
	text-align: right;
	width: 100%;
	margin: 13px 10px;
	height: 30px;
	padding: 4px 7px !important;
	border-radius: 4px;
}

.success {
	background-color: #f4fff4;
	color: #1db124;
	border: 1px solid #c7ffc3;
	font-size: 12px;
	display: inline-block;
	width: auto;
	border-radius: 2px;
	padding: 2px 4px;
}

.danger {
	background-color: #fff4f4;
	color: #ff2525;
	border: 1px solid #ffd0d0;
	font-size: 12px;
	display: inline-block;
	width: auto;
	border-radius: 2px;
	padding: 2px 4px;
}

.warning {
	background-color: #fffbeb;
	color: #D7A226;
	border: 1px solid #F9EEE1;
	font-size: 12px;
	display: inline-block;
	width: auto;
	border-radius: 2px;
	padding: 2px 4px;
}

.footer_flex {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 2px 0;
}

footer img {
	width: 70px;
	padding-bottom: 4px;
	height: 39px;
}

.section_doc {
	background: #f1f1f1;
	padding: 10px 20px;
}



.inner_image_upload_wapper {
	background: #fff;
	height: 100%;
}



.source-image {
	width: auto;
	height: auto;
}



input.readonly {
	background-color: #ebebeb;
}

.notification {
	border: 1px solid #cf0d0d;
	width: 100%;
	float: left;
	padding: 10px;
	margin-bottom: 35px;
}

.notice_board {
	display: flex;
	color: #d65050;
}

.notice_board i {
	font-size: 26px;
	color: #cf0d0d;
}

.message_board {
	padding-left: 13px;
}


.fieldlabels.label_doc {
	line-height: 13px;
	height: 30px;
}


.profile-view {
	border-radius: 4px;
	width: 100%;
	background: #fff;
	float: left;
}

.s-head {
	border: 1px solid #979797;
	background: #fff;
	/* border-radius: 3px; */
	/* margin-top:40px; */
	z-index: 999;
}

.s-head .result-header {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.s-head .result-header .post-img {
	width: 200px;
	height: 170px;
	overflow: hidden;
	object-fit: fill;
}

.s-head .result-header .post-img img {
	width: 100%;
	height: 100%;
	-o-object-fit: cover;
	object-fit: cover;
}

.s-head .result-header .user-info-detail {
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
}

.s-head .result-header .user-info-detail p {
	/* width: 100%; */
	float: left;
	text-transform: capitalize;
}

.profile-view .s-content {
	border-radius: 0 0 4px 4px;
}

.profile-view .s-content ul {
	list-style-type: disc;
	margin: 0 32px 12px;
}

.profile-view .s-content .mb-12 {
	margin-bottom: 12px;
}

.profile-view .s-content .profile-table {
	width: 100%;
	text-align: left;
	line-height: 3em;
	border-collapse: collapse;
	margin-bottom: 24px;
	font-size: 14px;
	vertical-align: top;
}

.profile-view .s-content .profile-table thead {
	border-bottom: 1px solid #F8911C;
	text-align: left;
}

.profile-view .s-content .profile-table thead th {
	padding: 0 16px;
	height: 44px;
	font-weight: 600;
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	z-index: 9;
	background: #fff;
	-webkit-box-shadow: 0 1px 1px #ddd;
	box-shadow: 0 1px 1px #ddd;
}

.profile-view .s-content .profile-table tbody tr:nth-child(even) {
	background: #f6f6f6;
}

.profile-view .s-content .profile-table tbody tr td {
	color: #1c1d1f;
	height: 40px;
	padding-left: 16px;
}

.sr-p-info {
	display: -ms-grid;
	display: grid;
	-ms-grid-columns: auto auto;
	grid-template-columns: 50% 50%;
	line-height: 10px;
}

.sr-p-info div {
	border-bottom: 1px solid #aeaeae;
	border-right: 1px solid #aeaeae;
	padding: 6px 10px;
}

.sr-p-info div:last-child {
	border-bottom: 0;
}

.sr-p-info div:nth-last-child(2) {
	border-bottom: 0 !important;
}

.sr-p-info div:nth-child(even) {
	border-right: 0 !important;
}

.header_logo {
	border-bottom: 1px solid #cacbca;
}

.header_logo span {
	position: relative;
	top: -20px;
	padding-left: 9px;
	font-size: 14px;
}

.p-body {
	font-size: 16px;
	font-family: "sfui-semibold";
	margin: 0;
}

.barcode img {
	width: 250px;
	height: 80px;
}

.fw-500 {
	font-weight: 600;
}

.line {
	border-bottom: 1px solid #979797;
	padding: 0px 10px;
	background: #f1f1f1;
}

.sr-p-info p {
	width: 100%;
	float: left;
	font-family: "sfui-regular";
	margin: 4px 0 0;
	line-height: 1.35;
	opacity: .88;
	text-overflow: ellipsis;
	overflow: hidden;
	overflow-wrap: break-word;
}

.total_box {
	font-size: 19px;
	font-family: "sfui-regular";
	padding-top: 5px;
}

.upper_bar,
.common_view {
	display: flex;
	justify-content: space-between;
	padding: 0 15px;
	min-height: 46px;
	flex-wrap: wrap;
}

.upper_bar {
	align-items: center;
}

.border_line {
	border: 0.5px solid #979797;
	margin-top: 10px;
}

.half_col {
	display: flex;
	justify-content: space-between;
	margin-top: 10px;
}

.half_col_box {
	width: 50%;
	border: 1px solid #979797;
}

.tab50 div {
	padding: 2px 10px;
	border-bottom: 1px solid #d7d7d7;
	width: 100%;
	float: left;
}

.tab50 label {
	width: auto;
}

.form-card {
	text-align: left;
	padding: 5px 15px;
}

.card_box_pass:hover {
	border: 1px solid green;
	box-shadow: 0 5px 10px rgb(0 0 0 / 9%), 0 8px 7px rgb(0 0 0 / 9%);
}

.grid-passport {
	display: grid;
	grid-template-columns: 66% 33%;
	grid-gap: 14px;
}

.passport_type input[type="radio"]+label {
	display: inline-block;
	cursor: pointer;
	position: relative;
	padding-left: 22px;
	margin-right: 20px;
	font-size: 13px;
	width: auto;
	line-height: 11px;
}

.passport_type input[type="radio"]+label:before {
	content: "";
	display: block;
	width: 12px;
	height: 12px;
	margin-right: 14px;
	position: absolute;
	top: -3px;
	left: 0;
	border: 1px solid #aaa;
	background-color: #fff;
	border-radius: 50%;
}

.passport_type input[type="radio"] {
	display: none !important;
}

.passport_type input[type="radio"]:checked+label:after {
	content: "";
	display: block;
	position: absolute;
	top: 0px;
	left: 3px;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: #0d7836;
}

.passport_box {
	width: 100%;
	height: 34px;

}

.tab50 div:nth-last-child(1) {
	border-bottom: 0px;
}

h4.seprate {
	font-size: 16px;
	color: #0d7836;
	padding: 15px 0 15px 0;
}

.seprate::after {
	content: "";
	border-bottom: 1px dotted #cfcfcf;
	width: 100%;
	float: left;
	padding: 2px 0;
}

.flex_row_home {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.flex_row {
	display: flex;
	justify-content: space-between;
}

.flex_row_header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.align-right {
	display: flex;
	justify-content: flex-end;
}

.legends span {
	display: inline-flex;
	align-items: center;
}

.legends span p {
	font-weight: 500;
	font-size: 14px;
}

.legends span i {
	color: #0d7836;
	font-size: 15px;

}

/* Payment CSS */

.card_box {
	border-top: 1px solid rgba(3, 3, 3, 0.1);
	box-shadow: 0px 1px 4px rgb(3 3 3 / 14%);
	background: #fff;
	margin-bottom: 10px;
	padding: 19px 20px;
}

.box_row {
	margin-top: 10px;
	width: 100%;
	float: left;
	border: 1px solid #cacbca;
}

.mt25 {
	margin-top: 25px;
}

.mt15 {
	margin-top: 15px;
}

.mt13 {
	margin-top: 13px;
}

.card_box img {
	width: 32px;
	margin-right: 10px;
	vertical-align: middle;
}

.h-1 {
	min-height: 232px;
}

.card_box p {
	font-size: 14px;
	font-weight: normal;
	margin-top: 20px;
}

a {
	text-decoration: none;
	color: #000;
}

.box_row h4 {
	color: #0d7836;
}

.faq-heading {
	font-weight: 400;
	font-size: 19px;
	-webkit-transition: text-indent 0.2s;
	text-indent: 20px;
	color: #333;
	padding: 10px 0;
	position: relative;
}

.faq-text {
	font-weight: 400;
	color: #171717;
	width: 100%;
}

.faq-text input {
	margin-left: 17px;
}

.faq {
	width: 100%;
	margin: 0 auto;
	background: white;
	border-radius: 4px;
	position: relative;
	margin-top: 10px;
}

.faq-l {
	display: block;
	position: relative;
	overflow: hidden;
	cursor: pointer;
	height: 56px;
	padding-top: 1px;
	margin-bottom: 10px;
	background-color: #FAFAFA;
	border: 1px solid #E1E1E1;
}

.faq input {
	width: auto;
	height: auto;
}

.faq-text label {
	width: auto;
	float: none;
	font-size: 14px;
	padding-left: 10px;
	cursor: pointer;
}

.gateway {
	display: none;
}

.faq .faq-arrow {
	position: absolute;
	top: 4px;
	right: 7px;
}

.faq img {
	width: 85px;
	height: auto;
}

.faq-heading input {
	width: auto;
	height: auto;
	margin-right: 14px;
}

.faq input[type="checkbox"]:checked+label {
	display: block;
	background: rgba(255, 255, 255, 255) !important;
	color: #4f7351;
	height: auto;
	transition: height 0.8s;
	-webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.faq input[type='checkbox']:not(:checked)+label {
	display: block;
	transition: height 0.8s;
	height: 56px;
	-webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.passport_fees table,
.passport_fees table tr,
.passport_fees table td {
	border: 0;
	padding: 0;
}

.passport_fees table td:nth-child(1) {
	width: 55%;
}

.passport_fees table td:nth-child(2) {
	width: 45%;
}

.passport_fees {
	background: #f1f1f1;
	padding: 10px 20px;
}

.pay-info {
	display: inline-flex;
	font-size: 14px;
	font-family: 'sfui-semibold';
}

.pay-info>* {
	margin-right: 10px;
}


/* HOME CSS */

.card_box_pass {
	border: 1px solid rgba(3, 3, 3, .1);
	box-shadow: 0px 1px 4px rgb(3 3 3 / 14%);
	background: #fff;
	padding: 9px 10px;
	cursor: pointer;
	height: 100%;
}

.card_box_item h3 {
	letter-spacing: 0;
	font-weight: 400;
	font-family: sfui-semibold;
}

.top_pass {
	display: flex;
	align-items: flex-start;
}

.mid-section h4 {
	font-size: 22px;
}

.rows_box {
	margin-top: 30px;
	width: 100%;
	float: left;
	margin-bottom: 27px;
}

.grid_three {
	display: grid;
	grid-template-columns: 32% 32% 32%;
	grid-gap: 15px;
}

.card_box_pass img {
	width: 50px;
	height: 50px;
	margin-right: 10px;
	vertical-align: middle;
	margin-top: 8px;
}

.h-1 {
	min-height: 100px;
}

.card_box_pass span {
	font-size: 14px;
	font-weight: normal;
	width: 100%;
	float: left;
}


/* RECEIPT CSS */

.pay-recipt table,
.pay-recipt table tr,
.pay-recipt table td {
	padding: 0 10px;
}

.pay-recipt table td:first-child {
	border-left: 0;
}

.pay-recipt table {
	margin-bottom: 8px;
}

.bank-notes h5,
.client-notes h5 {
	border: 0;
	border-bottom: 1px solid #aeaeae;
	background: #f1f1f1;
	padding: 4px 10px;
}

.pay-recipt h4 {
	background: #f1f1f1;
	padding: 4px 10px;
	border: 1px solid #aeaeae;
	border-bottom: 0 !important;
}

.pay-recipt table {
	border: 1px solid #aeaeae;
}

.pay-recipt {
	max-width: 650px;
	margin: 0 auto;
	margin-top: 10px;
	width: 100%;
}

.pay-recipt table th {
	background: #f1f1f1;
	color: inherit;
}

.bank-notes,
.client-notes {
	border: 1px solid #aeaeae;
	margin-bottom: 8px;
}

.bank-notes ul,
.client-notes ul {
	padding: 0 10px;
}

.pay-recipt h3 {
	background: #f96922;
	color: #fff;
	padding: 5px 10px;
	margin-bottom: 10px;
}

.app_detail,
.pay_ack {
	padding: 0 12px;
}

.app_detail h4,
.pay_ack h4 {
	color: #0d7836;
}

.captcha {
	width: 100%;
	float: left;
}

.h_logo {
	display: none;
}

.h_logo .status_img {
	width: 75px;
}

.h_logo,
.h_logo td {
	border: 0;
}

.visa_type p {
	font-size: 25px;
	line-height: normal;
}

.tabordion {
	color: #333;
	display: block;
	margin: auto;
	position: relative;
	width: 100%;
}

.tabordion input[name="sections"] {
	left: -9999px;
	position: absolute;
	top: -9999px;
}

.tabordion section {
	display: block;
}

.tabordion section label {
	border-right: 2px solid #0d7836;
	cursor: pointer;
	display: block;
	font-size: 16px;
	padding: 21px 20px;
	position: relative;
	width: 180px;
	float: none;
	color: #bbb;
	z-index: 99;
}

.tabordion section article {
	display: none;
	left: 230px;
	min-width: 300px;
	padding: 0 0 0 21px;
	position: absolute;
	top: 0;
}

.tabordion section article:after {
	bottom: 0;
	content: "";
	display: block;
	left: -229px;
	position: absolute;
	top: 0;
	width: 220px;
	z-index: 1;
}

.tabordion input[name="sections"]:checked+label {
	background: #eee;
	color: #000;
}

.tabordion input[name="sections"]:checked~article {
	display: block;
}


/* # The Rotating Marker #*/

details summary::-webkit-details-marker {
	display: none;
}

details summary::before {
	content: "▶";
	position: absolute;
	top: 4px;
	left: 0.8rem;
	transform: rotate(0);
	transform-origin: center;
	transition: 0.2s transform ease;
	appearance: none;
	-webkit-appearance: none;
	background: #f96922;
}

details[open]>summary:before {
	transform: rotate(90deg);
	transition: 0.45s transform ease;
}

details {
	box-sizing: border-box;
	margin-bottom: 15px;
	background: #fff;
	display: none;
	width: 100%;
	float: left;
	position: relative;
}

details summary {
	outline: none;
	padding: 5px;
	display: block;
	background: #f96922;
	color: white;
	padding-left: 2.2rem;
	position: relative;
	cursor: pointer;
	z-index: 10;
	border-radius: 2px;
}

details .content {
	padding: 10px;
	border: 2px solid #888;
	border-top: none;
	position: absolute;
	z-index: 9999;
	background: #fff;
	width: 100%;
	box-shadow: 0 5px 10px rgb(0 0 0 / 9%), 0 8px 7px rgb(0 0 0 / 9%);
}

.history_info-inner table tr {
	table-layout: fixed;
	display: table;
	width: 100%;
}

/* # The Sliding Summary # */

@keyframes details-show {
	from {
		margin-bottom: -80%;
		opacity: 0;
		transform: translateY(-100%);
	}
}

details>*:not(summary) {
	animation: details-show 500ms ease-in-out;
	position: relative;
	z-index: 1;
	transition: all 0.3s ease-in-out;
	color: transparent;
	overflow: hidden;
}

details[open]>*:not(summary) {
	color: inherit;
}

details .content ul {
	list-style-type: disc;
	margin-left: 10px;
}


/* COD CSS Start */
.mt15 {
	margin-top: 15px;
}

.mt30 {
	margin-top: 30px !important;
}

.mb30 {
	margin-bottom: 30px !important;
}

.mt20 {
	margin-top: 20px;
}

.mt10 {
	margin-top: 10px;
}

.title_btn {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.logo {
	margin-top: 20px;
}

.green_row {
	background: #0d7836;
	width: 100%;
	color: #fff;
	padding-left: 7.2%;
}

.cod-Data {
	width: 100%;
	float: left;
}

.box ol {
	list-style-position: outside;
	padding-left: 20px;
}

h3.heading-green {
	color: #0D7836;
}

.box {
	border-left: 0.5px dotted #d8d8d8;
	padding: 8px 10px;
	margin-top: 15px;
}

.box:first-child {
	border-left: 0;
}

.box h4 {
	margin-bottom: 8px;
}

.tabs-container {
	position: relative;
	background: #F3F3F3;
	width: 320px;
	height: 560px;
	float: left;
	z-index: 20;
	border: 1px solid #d7d7d7;
}

.tabs-container label {
	position: relative;
	padding: 7px 10px 6px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	display: block;
	font-size: 13px;
	cursor: pointer;
	user-select: none;
}

.tabs-container label:hover {
	background: rgba(0, 0, 0, 0.2);
}

.tab-content {
	position: relative;
	background: #fff;
	width: calc(100% - 320px);
	height: 560px;
	float: left;
	box-sizing: border-box;
	z-index: 19;
	border: 1px solid #d7d7d7;
	border-left: 0;
	margin-bottom: 50px;
}

.tab-content:after {
	content: "";
	clear: both;
}

.tab-content .back_btn,
.tab-content .custom_btn {
	margin-top: 8px;
}

.tab-content h3 {
	background: #ebebeb;
	padding: 3px 15px;
	border-bottom: 1px solid #d7d7d7;
}

.tab-content .grid_two {
	padding: 10px 15px;
}

input[name="tab"] {
	display: none;
}

input[name="tab"]:checked+.tab-content {
	display: block;
	animation: slide 0.5s forwards;
}

.cod-Data .bk {
	background: #f1f1f1;
	padding: 25px 20px 20px;
	width: 100%;
}

.infoyn {
	width: auto;
	float: none;
	height: auto;
	padding: 0px 6px;
}

.element {
	border-color: #f96922;
	-o-border-image: #f96922;
	border-image: #f96922;
	position: relative;
}

.element:before {
	content: 'i';
	background: #f96922;
	padding: 4px 11px;
	border-radius: 50%;
	position: absolute;
	top: 12px;
	left: -19px;
	border: 3px solid #fff;
	font-family: serif;
	font-weight: 700;
	color: #fff;
}

.element {
	background-clip: content-box;
	background-color: inherit;
	border-left: 6px solid #f96922;
	margin-bottom: 23px;
	padding-left: 20px;
}

.card_box_pass h4 a {
	display: flex;
	align-items: center;
}

.card_box_pass h4 a i {
	padding-left: 7px;
}

.half_w {
	width: 25%;
}


/* COD CSS End */

.blinkingText {
	font-size: 20px;
	padding-top: 8px;
	color: red;
	line-height: 20px;
}

.highlight_txt {
	font-size: 16px;
	color: #f96922;
}

.h_txt {
	height: auto !important;
}

.common_view label,
.common_view p {
	font-size: 18px;
}

.cod-change-field th:first-child {
	padding-left: 15px;
}

.card table tr td:first-child {
	padding-left: 5px !important;
	border-left: 0 !important;
}

.iti {
	width: 100%;
}

.gateway-fee-note {
	color: red;
}

.book-appointment-note p {
	color: red;
	text-align: left;
}

.confirmbox-container p {
	overflow-wrap: break-word;
}

/* HOME PAGE CSS START */
.banner-img_home {
	background: url('../../assets/images/pass.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	width: 100%;
	height: 320px;
	float: left;
	position: relative;
	overflow: hidden;
}

.banner_text {
	width: 100%;
	float: left;
	color: #fff;
	position: absolute;
	top: 35%;
	left: 8%;
	line-height: 42px;
}

.banner_text hr {
	border: 1px solid #f96922;
	width: 40px;
	margin: 10px 0;
}

.main-bottom {
	background: #F5F5F5;
	width: 100%;
	text-align: center;
	margin-bottom: 40px;
	height: 120px;
	position: relative;
	box-shadow: 0 14px 28px rgb(0 0 0 / 9%), 0 10px 10px rgb(0 0 0 / 9%);
}

.img_num img {
	float: right;
	position: absolute;
	right: 0;
	bottom: 0;
}

.loginBtn {
	cursor: pointer;
}

.menu ul li {
	display: inline-block;
	margin-left: 19px;
	/* margin-top: 17px !important; */
	vertical-align: middle;
}

img.logo {
	width: 172px;
}

.logo-header {
	display: flex;
}

nav img {
	width: 200px;
}


.nav-links li a:hover::before {
	width: 80%;
}

.adjust-height {
	min-height: 600px;
}

.right_top span {
	padding-left: 9px;
	font-size: 14px;
	font-family: sfui-semibold;
}

.dropdown a:hover {
	color: #f96922 !important;
}

/* HOME PAGE CSS END */
/* DASHBOARD PAGE CSS START */
.new_app .save_btn,
.new_app .custom_btn {
	margin: 0 3px;
	position: relative;
	z-index: 99;
	font-size: 14px;
	font-family: "sfui-semibold";
}

.height_fixx {
	height: 100%;
	max-height: calc(100vh - 330px);
	overflow-y: auto;
	border-bottom: 1px solid #ececec;

}

/* DASHBOARD PAGE CSS END */

.card .legends span p,
.card .legends span i {
	color: #d70606;
}

.pay_btn {
	background: #e2b227 !important;
	border: 1px solid #bcac0a !important;
}

.pay_btn:hover {
	background: #d29f0a !important;
}

.app-confirm {
	color: red;
}

.pdf-label {
	color: #797878
}

.print_reciept td {
	font-weight: 600;
}

.print_reciept td span {
	font-weight: 500
}

li.dropdown i {
	padding-left: 7px;
}

.bottom-deatils-content {
	width: 100%;
	float: left;
	text-align: left;
	position: fixed;
	bottom: 0;
}

.menu {
	display: flex;
	flex-direction: row;
	list-style-type: none;
	margin: 0;
	padding: 0;
	align-items: center;
}

.menu>li {
	margin: 0 1rem;
}

.menu-button-container {
	display: none;
	height: 100%;
	width: 30px;
	cursor: pointer;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

#menu-toggle {
	display: none;
	position: relative;
	left: 21px;
}

.menu-button,
.menu-button::before,
.menu-button::after {
	display: block;
	background-color: #282928;
	position: absolute;
	height: 4px;
	width: 30px;
	transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
	border-radius: 2px;
}

.menu-button::before {
	content: "";
	margin-top: -8px;
}

.menu-button::after {
	content: "";
	margin-top: 8px;
}

#menu-toggle:checked+.menu-button-container .menu-button::before {
	margin-top: 0px;
	transform: rotate(405deg);
}

#menu-toggle:checked+.menu-button-container .menu-button {
	background: rgba(255, 255, 255, 0);
}

#menu-toggle:checked+.menu-button-container .menu-button::after {
	margin-top: 0px;
	transform: rotate(-405deg);
}

/* my menu */
.header_row {
	width: 90%;
	display: flex;
	align-items: center;
	padding: 0 45px;
	justify-content: center;
	margin: 0 auto;
}

.header_wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	flex-wrap: wrap;
}

.right_sides.right_top_1 {
	width: calc(100% - 270px);
	display: flex;
	justify-content: flex-end;
}

.for_menu,
.user_log {
	width: auto;
	float: left;
	display: flex;
	align-items: center;
}

/* NEW FLOW CSS ADDED */
.book_center {
	color: #DF692F;
	font-size: 12px;
	margin-top: -2px;
	width: 100%;
	float: left;
}

.line {
	border-bottom: 1px solid #979797;
	padding: 0px 10px;
	background: #f1f1f1;
	text-align: left;
}

pdf-viewer {
	width: 100%;
	float: left;
	z-index: 1002;
	position: relative;
	border: 1px solid #cdcccc;
}

.pdf-viewer-with-option {
	height: 438px;
}

.pdf-viewer-without-option {
	height: 510px;
}

.view_pdf pdf-viewer {
	height: calc(100% - 10px);
}


/* .view_pdf pdf-viewer{height: 550px;} */
.view_pdf img {
	text-align: left;
	width: auto;
	float: left;
}

.view_pdf p {
	background: #e7e7e7;
	border: 1px solid #d9d9d9;
	text-align: left;
	margin-bottom: 10px;
	padding: 3px 10px;
}

.margin60 {
	margin-top: 60px;
}

p.guidlines {
	line-height: 17px;
}

.details_photo li {
	list-style: disc;
	margin-left: 17px;
	text-align: left;
}

.fileUpload input.upload {
	position: absolute;
	top: 0;
	right: 0;
	margin: 0;
	padding: 0;
	cursor: pointer;
	opacity: 0;
	overflow: scroll;
}

.grid-photo-two {
	display: grid;
	grid-template-columns: 35% 60%;
	grid-column-gap: 53px;
	text-align: left;
}

.grid-photo-two,
.guidelines-container {
	grid-template-columns: 100%;
}

.grid-photo-two {
	width: 100%;
	float: left;
}

.support_document table th {
	background: none;
}

.support_document table tr td {
	border-left: 0;
	height: 40px;
	vertical-align: text-top;
}

.support_document table tr td {
	border-left: 0;
	vertical-align: text-top;
	padding-top: 2px;
	padding-bottom: 15px;
	position: relative;
}

.support_document table tr td .alert {
	position: absolute;
	bottom: 3px;
	left: 10px;
}

.support_document table tr td.actionable {
	/* text-align: right; */
}

.actionable {
	text-align: right;
}

.actionable span {
	padding: 1px 5px;
	border-radius: 3px;
	margin-right: 5px;
	font-size: 12px;
	cursor: pointer;
}

.actionable .upload {
	background: #a9f3a9;
	border: 1px solid #b3cdb3;
	color: green;
}

.actionable .preview {
	background: #baecff;
	color: #125a87;
	border: 1px solid #5cb1cb;
}

.actionable .delete {
	background: #fdcfcf;
	color: #e12121;
	border: 1px solid #fba2a2;
}

.actionable .check i {
	color: green;
	font-size: 14px;
	cursor: default;
}

.profile_table {
	border: 0 !important;
	padding: 0;
	margin: 0;
	margin-top: 10px !important;
	vertical-align: text-top;
	table-layout: fixed;
}

.profile_table tr {
	border: 0 !important;
}

.profile_table tr td:first-child,
.profile_table tr td {
	padding-left: 0 !important;
	border-left: 0 !important;
	vertical-align: top;
	padding-right: 0;
}

.pt-10 {
	padding-top: 10px;
}

.profile_table td.tab-d-print {
	padding-left: 5px !important;
}

.pright5 {
	padding-right: 5px !important;
}

.half_col_box_doc {
	border: 1px solid #979797;
	width: 100%;
	float: left;
}

.half_col_box_doc .tab50 div {
	padding: 0;
}

.mb-70 {
	margin-bottom: 70px;
}

.user_pic {
	border-right: 1px solid #ada7a7;
}

.user_pic img {
	width: 35mm;
	height: 100%;
	object-fit: fill;
}

.mb10 {
	margin-bottom: 10px;
}

.tab50 p {
	color: #167816;
	padding: 2px 10px;
	text-align: left;
}

.tab50 label {
	width: auto;
	padding: 4px 10px;
}

.tab50 span {
	padding: 2px 10px;
}

.nin_verify_grid {
	display: grid;
	grid-template-columns: calc(35% - 20px) 65%;
	width: 100%;
	gap: 20px;
}

.guidlines a {
	color: #188fd9;
	text-decoration: underline;
	font-family: sfui-semibold;
}

.grid-boxs .form_box {
	height: 75px;
}

.personal_details_section table.request_table {
	width: 100%;
}

.personal_details_section table {
	width: 600px;
}

.personal_details_section table tr td:first-child {
	border-left: 0;
	width: 30%;
	font-size: 13px;
}

.personal_details_section table tr:nth-child(even) {
	background: #f2f2f2;
}

.img_box img {
	width: auto;
	border: 1px solid #cacbca;
	max-width: 100%;
	object-fit: contain;
	/* height: 260px; */
}

.guidelines-container {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: calc(100vw - 250px) !important;
	padding: 0 !important;
	background: #fff;
	padding: 15px;
	border-radius: 6px;
	text-align: left;
}


.guidelines-container img {
	width: 800px;
	height: auto;
}

.grid-guideline .grid-boxs {
	height: 100%;
	overflow: hidden;
}

.grid-guideline table tbody {
	max-height: calc(100vh - 200px);
	background: #fbfbfb;
	/* display: block;
  	height: auto;
  	overflow-y: auto; */
}

.grid-guideline table tbody tr:nth-child(even) {
	background-color: #efefef;
}

.grid-guideline table tbody td:first-child {
	font-weight: 600;
	vertical-align: top;
	width: 160px;
}

.grid-guideline table tbody td:last-child {
	width: calc(100% - 160px);
}

.photo-ex-inner {
	float: left;
	width: 100%;
	height: 100%;
}

.photo-ex-inner .tabs_vertical {
	height: calc(100% - 100px);
	overflow-y: auto;
	background: #ebebeb;
}

ul.guidelines_list {
	padding-left: 20px;
	padding-bottom: 15px;
	list-style: disc;
}

.guide_wrapper {
	display: flex;
	height: 100%;
	justify-content: space-between;
}

.tab {
	cursor: pointer;
	padding: 10px 10px;
	margin: 0px 2px;
	background: #0d7836;
	display: inline-block;
	color: #fff;
	border-bottom: 1px solid #178d44;
}

.tabs {
	width: 210px;
	box-shadow: 0 0.5rem 0.8rem #00000080;
	background: #0d7836;
}

.panels {
	width: calc(100% - 210px);
	max-width: 100%;
	overflow-y: auto;
	padding: 20px;
}

.tabs_vertical.g-w .panels {
	background: rgb(235 235 235 / 96%);
	width: calc(100% - 210px);
	max-width: 100%;
	border-radius: 3px;
	overflow: hidden;
	padding: 20px;
	border: 1px solid #dbdbdb;
}

.panel {
	display: none;
	animation: fadein .8s;
	position: relative;
}

.instruction_box ul li,
.upload_inst ul li {
	list-style-type: disc;
	text-align: left;
	margin-left: 16px;
	padding-top: 3px;
	line-height: 21px;
}

.radio_guide {
	display: none;
}

#one:checked~.panels #one-panel,
#two:checked~.panels #two-panel,
#three:checked~.panels #three-panel,
#four:checked~.panels #four-panel,
#five:checked~.panels #five-panel,
#six:checked~.panels #six-panel,
#seven:checked~.panels #seven-panel,
#eight:checked~.panels #eight-panel,
#nine:checked~.panels #nine-panel,
#ten:checked~.panels #ten-panel {
	display: block
}

#one:checked~.tabs #one-tab,
#two:checked~.tabs #two-tab,
#three:checked~.tabs #three-tab,
#four:checked~.tabs #four-tab,
#five:checked~.tabs #five-tab,
#six:checked~.tabs #six-tab,
#seven:checked~.tabs #seven-tab,
#eight:checked~.tabs #eight-tab,
#nine:checked~.tabs #nine-tab,
#ten:checked~.tabs #ten-tab {
	background: #fff;
	color: #0d7434;
}

.panel img {
	width: 200px;
	height: 200px;
	border: 1px solid #cbcbcb;
}

.heading_title {
	display: inline-block;
	width: 100%;
	padding: 5px 22px;
	position: relative;
	color: #a1a1a1;
	border-right: 1px solid #dfdfdf;
	background-color: #f7f3f3;
	border: 1px solid gainsboro;
	margin-bottom: 10px;
}

.photo_grid {
	display: grid;
	grid-template-columns: 46% 51%;
	grid-column-gap: 46px;
}

.heading_title .active_li .title_li {
	color: #00743d;
	font-weight: 600;
}

.pic_guide h4 {
	line-height: 18px;
	padding-bottom: 4px;
}

.pic_guide li {
	list-style: decimal;
	margin-left: 15px;
	line-height: 24px;
}

.pic_guide p {
	line-height: 18px;
}

.tick_mark {
	font-size: 35px;
	position: absolute;
	left: -11px;
	top: -25px;
}

.tick_mark .fa-check {
	border: 1px solid #1acb1a;
	color: #1acb1a;
	background: #cbffdf;
	border-radius: 50%;
	vertical-align: middle;
	padding: 7px 8px;
	font-size: 25px;
}

.tick_mark .fa-times {
	color: #ffe2e2;
	background: #e53232;
	border-radius: 50%;
	vertical-align: middle;
	padding: 4px 8px;
	font-size: 25px;
}

.tick_mark {
	left: -4px;
	top: -24px;
}

.tick_mark .fa-check,
.tick_mark .fa-times {
	padding: 6px 6px;
}

input#myFile {
	position: absolute;
	opacity: 0;
	border: 0;
	left: 0;
	cursor: pointer !important;
	top: 0;
	width: 100px;
	height: 35px;
	padding: 6px 8px;
}

.upload_btn {
	background: #d1ffd1;
	border: 1px solid #c5d9c5;
	color: green;
	border-radius: 3px;
	position: relative;
	min-width: 50px;
	width: auto;
	display: inline-block;
	padding: 6px 12px;
	text-align: center;
	position: relative;
	z-index: 99;
	height: 35px;
	cursor: pointer;
}

.capture_btn {
	background: #ffed97;
	color: #706a0f;
	border: 1px solid #cfcf24;
	margin-left: 5px;
	border-radius: 3px;
	font-family: 'sfui-semibold';
}

.wid40 {
	width: 40%;
}

.wid20 {
	width: 20%;
}

.validate_btn {
	background: #eb9b22;
	color: #fff;
	position: relative;
	font-family: 'sfui-semibold';
	min-width: 60px;
	width: auto;
	border-radius: 3px;
}

.details_photo li {
	list-style: disc;
	margin-left: 17px;
	text-align: left;
}

.photo_box {
	border: 1px dotted #999;
	height: 375px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.instructionss {
	text-align: left;
}

.grid-photo-two {
	display: grid;
	grid-template-columns: 375px calc(100% - 395px);
	text-align: left;
	gap: 18px;
}

.issue_box {
	border: 1px solid #ebe9e9;
	background: #f1f1f1;
	padding: 10px 20px;
	text-align: left;
	/* height: 280px; */
	overflow-y: auto;
	margin-bottom: 10px;
}

.issue_box ul {
	padding-left: 20px;
}

.issue_box ul li {
	list-style: disc;
}

.center-content {
	justify-content: center;
}

ngx-super-croppie {
	position: relative;
}

.icons_uplaod_1 {
	margin-top: 10px;
}

.upload_boxx {
	position: relative;
	width: 100%;
	float: left;
	margin: 0 auto;
}

.croppie-container {
	width: 100%;
	height: auto !important;
}

.croppie-container .cr-slider-wrap {
	width: 75%;
	margin: 5px auto 0 !important;
}

.file-upload .croppie-container {
	width: auto;
	float: left;
	text-align: left;
}

.file-upload .croppie-container .cr-slider-wrap {
	margin: 7px 0 0 !important;
}

button.filt--upload {
	background: #d1ffd1;
	color: #0D7836;
	padding: 7px 10px;
	border-radius: 3px;
	border: 1px solid #c5d9c5;
	cursor: pointer !important;
	font-size: 13px;
}

.file-upload .photo_box {
	max-width: 500px;
	width: 100%;
	height: 300px;
}

.files_btn {
	text-align: left !important;
}

.bottom_instruction,
.view_pdf p {
	text-align: left;
}

.bottom_instruction ul {
	list-style: disc;
	padding-left: 15px;
}

.wid400 {
	width: 500px;
	float: left;
}

.form_height {
	height: 74px;
}


.mat-expansion-panel-content .mat-expansion-panel-body {
	padding: 20px 24px 20px;
	text-align: left;
	background: #eee;
}

.mat-expanded .mat-expansion-panel-content .mat-expansion-panel-body {
	border-top: 1px solid #ddd;
}

.main .heading_main {
	font-size: 45px;
	padding: 0 0 80px;
}

.image_upload_container {
	width: calc(100% - 20px);
	max-width: 1100px;
	padding: 0 !important;
	background: #fff;
	border-radius: 0;
	margin: 0 auto;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.image_upload_container .modal-body {
	height: 580px;
	padding: 10px 10px 0;
	overflow: hidden;
}


.modal-header {
	background: #0D7836;
	color: #fff;
	text-align: left;
	height: 45px;
}

.modal-header i {
	text-align: right;
	color: #fff;
}

.modal-header h4 {
	width: 100%;
	float: left;
	padding: 4px 0;
	font-weight: 400;
}

.confirmbox-container .modal-header,
.confirmbox-container .modal-body,
.confirmbox-container .modal-footer {
	text-align: left !important;
}

.confirmbox-container .modal-body p span {
	color: red;
}

.guidelines-container .modal-body {
	height: calc(100vh - 125px);
	overflow: hidden;
}

.image_upload_container .centered {
	position: relative;
	margin: 0 auto;
	height: 100%;
	overflow-y: auto;
}

.image_upload_container .centered .grid-one-third,
.image_upload_container .file-upload {
	height: 100%;
}

.image_upload_container .form_box+.file-upload {
	height: calc(100% - 74px);
}

.image_upload_container .centered .grid-boxes {
	height: 100%;
}

.image_upload_container .centered .grid-boxes-upload {
	overflow-y: auto;
}

#msform {
	position: relative;
	padding: 12px 16px 8px;
}

.icon_dummy i {
	font-size: 70px;
	opacity: .4;
	padding: 20px;
}

.image-cropper-box {
	height: calc(100% - 60px);
	border: 1px solid #cdcccc;
	overflow-y: auto;
}

.image-cropper-box img {
	max-width: 100%;
	display: flex;
}

.doc-data {
	height: calc(100% - 10px);
	border: 1px solid #d9d9d9;
	overflow-y: auto;
}

.doc-data img {
	max-width: 100%;
	object-fit: contain;
	display: flex;
}

.documentt_view tr td {
	border-bottom: 1px solid #d7d7d7;
}

table.request_table {
	border: 1px solid #cacbca;
}

.request-detail .form-card {
	width: 100%;
}

table.request_table tr {
	float: none;
	display: table-row;
	border-bottom: 1px solid #cacbca;
	width: auto;
}

table.request_table tr:last-child {
	border-bottom: none;
}

table.request_table td {
	display: table-cell;
	text-align: left;
	width: auto;
	float: none;
}

.request_table tr:first-child td,
.request_table tr td:first-child {
	background: #ebebeb;
	text-shadow: 0 0 BLACK;
}

.request_table tr:first-child td:first-child,
.request_table tr td:first-child {
	width: 150px;
}

.request_table .fa-times-circle {
	color: red;
}

.request_table .fa-check-circle {
	color: green;
}

.cod-popup {
	max-width: 1100px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: calc(100vw - 90px);
	background: #fff;
	padding: 0;
	border-radius: 6px;
	text-align: left;
	overflow: hidden;
	max-height: calc(100vh - 100px);
	overflow-y: auto;
	display: flex;
	flex-direction: column;
}

.cod-Data {
	width: 100%;
	float: left;
}

.cod-Data .bk {
	background: #f1f1f1;
	padding: 25px 20px 20px;
	width: 100%;
}

.tabordion-list {
	width: 150px;
	border-right: 2px solid #0d7836;
}

.tabordion-list li {
	cursor: pointer;
	font-size: 16px;
	padding: 20px 20px;
	color: #bbb;
	font-weight: bold;
}

.tabordion-output {
	width: calc(100% - 150px);
	padding: 0 0 0 40px;
}

.tabordion-list li.active {
	background: #eee;
	color: #000;
}

.tabordion {
	position: relative;
	display: flex;
	margin-bottom: 20px;
}

.cod-change-data {
	margin-bottom: 0;
	width: 100%;
	border: none;
}

.cod-change-data th {
	background: #f1f1f1;
	color: rgb(67, 67, 67);
	border-color: #979797;
	height: auto;
}

.cod-change-data tr td:first-child {
	border-left: none;
	font-family: "sfui-semibold";
}

.cod-change-data tr,
.cod-change-head {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	border-color: #979797;
}

.cod-change-data td {
	/* border-color: #979797; */
	height: auto;
	padding-left: 10px;
	text-align: left;
	border-left: 1px solid #979797;
	line-height: 20px;
}

.image_upload_radio_container {
	gap: 1rem;
}

.flex_row_passport_delivery {
	display: flex;
	align-items: center;
}

.radio-span {
	gap: 5px;
	align-items: center;
}

.image_upload_radio {
	width: auto !important;
}

.radio-grid-wrap {
    padding-bottom: 10px;
    position: relative;
}

.radio-grid-wrap .alert {
    position: absolute;
    bottom: 0;
    left: 0;
}

.radio-grid .radio-span {
	width: fit-content;
}

.radio-grid {
	padding: 0 0 5px;
}

@media (min-width: 1025px) and (max-width: 1340px) {
	.form_box {
		margin-top: 10px;
	}
}

@media (min-width: 768px) and (max-width: 1340px) {
	.support_document table tr td {
		padding-bottom: 20px;
	}

}

@media (min-width: 768px) and (max-width: 1250px) {
	.re_upload_box {
		width: 50%;
		padding: 0 0 5px;
	}
}


@media (max-width: 1359px) {
	.main .heading_main {
		font-size: 35px;
		padding: 0 0 50px;
	}
}

@media (max-width: 1199px) {
	.tabset {
		top: 0;
	}

	td.upload-support-doc {
		width: 70%;
	}

	td.upload-support-doc-btn {
		width: 30%;
	}

	.header_row {
		padding: 0;
	}
}

@media (min-width: 992px) and (max-width: 1330px) {
	.nin_verify_grid {
		grid-template-columns: calc(40% - 3px) 60%;
	}
}

@media (min-width: 992px) and (max-width: 1199px) {
	.menu>li {
		margin: 0 10px;
	}

	nav img {
		max-width: 150px;
	}

	.right_sides.right_top_1 {
		width: calc(100% - 220px);
	}

	nav.header-session img {
		max-width: 100%;
	}
}

@media (max-width: 991px) {
	nav img {
		width: 150px;
	}

	.menu {
		position: absolute;
		top: 67px;
		margin-top: 6px;
		left: 0;
		flex-direction: column;
		width: 100%;
		text-align: left;
	}

	#menu-toggle~.menu li {
		height: 0;
		margin: 0;
		padding: 0;
		border: 0;
		transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
	}

	#menu-toggle:checked~.menu {
		height: 100vh !important;
		background: #222 !important;
		opacity: .95 !important;
	}

	#menu-toggle:checked~.menu li {
		border: 1px solid #333;
		height: auto;
		padding: 0.5em;
		transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
		display: block;
	}

	.menu>li {
		display: flex;
		justify-content: center;
		margin: 0;
		padding: 0.5em 0;
		width: 100%;
		color: white;
		background-color: #222;
		display: none;
	}

	.menu>li:not(:last-child) {
		border-bottom: 1px solid #444;
	}

	.menu li a {
		color: #fff;
		width: 100%;
		display: block;
	}

	.menu-button-container {
		display: flex;
	}

	.header-session .for_menu {
		order: 2;
	}

	.right_sides.right_top_1 {
		column-gap: 10px;
	}

	.header-login-session .user_log {
		display: none;
	}

	.small-screen-username {
		display: block;
	}

	.main,
	.main-footer {
		width: 100% !important;
		padding-right: 30px;
		padding-left: 30px;
	}

	.main .heading_main {
		font-size: 25px;
	}

	.header_row {
		width: 100%;
		padding: 0;
	}

	.right_sides.right_top_1 {
		padding-right: 10px;
	}

	.left_sides {
		padding-left: 10px;
	}

	td.upload-support-doc {
		width: 66%;
	}

	td.upload-support-doc-btn {
		width: 34%;
	}

	button.mat-menu-btn.mat-menu-item {
		padding: 0 11px;
	}
}

@media only screen and (min-width: 765px) and (max-width: 1024px) {

	.top_head {
		padding: 5px 20px;
	}

	.menu ul li {
		margin-left: 12px;
		margin-top: 10px;
	}

	.nav-links {
		display: block;
		list-style: none;
		width: auto;
		padding: 0;
		text-transform: capitalize;
	}

	.instruction_box {
		padding: 15px;
	}

	.main1 {
		width: 100%;
		padding-right: 40px;
		padding-left: 40px;
	}

	.faq {
		padding-left: 20px;
	}

	.box_row h4 {
		padding-left: 20px;
	}

	.passport_fees h4 {
		padding: 10px 0;
	}

	.grid-two,
	.grid-payment {
		grid-template-columns: 100%;
	}

	.cart-info {
		height: auto;
	}

	.grid-two.tab_screen {
		grid-template-columns: 49% 49%;
	}

	.notice_modal {
		max-width: 85% !important;
	}

	.grid_three {
		grid-template-columns: 49% 49%;
		grid-gap: 15px;
	}

	.confirmbox-container {
		width: 85% !important;
	}

	.grid-date {
		grid-template-columns: 20% 50% 20%;
	}

	.modal-content {
		width: 98%;
	}

	.cod-Data .bk {
		padding: 10px 10px 0;
	}

	.tabs-container {
		width: 180px;
		height: 575px
	}

	.tab-content {
		width: calc(100% - 180px);
		height: 575px;
	}

	.tab-content .grid-two {
		grid-template-columns: 49% 49% !important;
	}

	.common_view label,
	.common_view p {
		font-size: 0.90em;
	}

	.search_grid {
		grid-template-columns: 40% 3% 52%;
	}

	.tabset>label {
		width: 33.3%;
	}

	.main-bottom {
		height: 140px;
	}

	.card {
		width: 97%;
	}
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
	.confirmbox-container {
		width: 85% !important;
	}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {

	.help_item ul li .help_item_list {
		font-size: 13px;
	}

	.help_item ul li .help_item_list {
		width: 160px;
		font-size: 13px;
	}

	.help_item ul li p {
		width: calc(100% - 160px);
	}

	.nav-links li a {
		font-size: 14px;
	}

	.custom_btn1 {
		font-size: 13px;
	}

	.menu>li {
		margin: 0 10px;
		font-size: 13px;
	}

	.right_sides.right_top_1 {
		width: calc(100% - 230px);
	}

	.new_app .save_btn,
	.new_app .custom_btn {
		font-size: 12px;
	}

	nav.header-session img {
		width: 200px;
	}

	.grid-guideline {
		grid-template-columns: 100%;
		gap: 0;
	}

	.grid-guideline .grid-boxs {
		padding-top: 20px;
	}
}

.small-screen-username {
	display: none;
}

.user-account-container .fa-user-circle-o {
	font-size: 25px !important;
}

.user-account-container h4 {
	padding-bottom: 0;
}

.user-account-container {
	display: flex;
	gap: 10px;
	align-items: center;
}

@media only screen and (max-width: 767px) {

	.grid-personal,
	.grid-two,
	.grid-container,
	.grid-payment,
	.nin_verify_grid,
	.grid-photo-two,
	.guidelines-container,
	.grid-guideline {
		grid-template-columns: 100%;
		gap: 0;
	}

	.cod-change-data td {
		padding-left: 6px;
	}

	.main {
		width: 100% !important;
		padding-right: 10px;
		padding-left: 10px;
		height: auto;
		padding-bottom: 0;
	}

	body,
	body p,
	p,
	.p-body {
		font-size: 13px;
	}

	.new_app {
		padding: 2px 0 10px;
	}

	.pl-10 {
		padding-left: 10px;
	}

	.header_box span {
		position: absolute;
		top: 47px;
		padding-left: 3px;
		font-size: 13px;
		font-family: "sfui-semibold";
		width: 100%;
		float: left;
	}

	.grid-guideline .grid-boxs:last-child {
		padding-top: 30px;
	}

	.grid-guideline .grid-boxs:first-child {
		border: 1px solid #0d7836;
	}

	.grid-guideline table tbody td:first-child,
	.grid-guideline table tbody td:last-child {
		text-align: left;
		width: 100%;
	}

	nav.header-session {
		display: flex;
		align-items: center;
	}


	.header_box {
		display: flex;
		position: relative;
	}

	.history_modal {
		flex-direction: column;
	}

	.history_info_prv {
		width: 100%;
		margin-top: 10px
	}

	.re-upload-table.re-upload-doc th {
		position: relative;
		padding-left: 5px;
	}

	.image_upload_container.image-upload-large .modal-body {
		max-height: 500px;
		/* height: auto; */
	}


	.grid-one-third {
		grid-gap: 0;
	}

	.g-w .wrapper {
		flex-wrap: wrap;
	}

	.tabs_vertical.g-w .panels {
		width: 100%;
	}

	.tabs {
		width: 100%;
	}

	.flex_row {
		display: block;
	}

	.pay_ment thead {
		border: none;
		clip: rect(0 0 0 0);
		height: 1px;
		margin: -1px;
		overflow: hidden;
		padding: 0;
		position: absolute;
		width: 1px;
	}

	.pay_ment td,
	.pay_ment tr {
		width: 100%;
		float: left;
	}

	.pay_ment tr {
		display: block;
	}

	.pay_ment td {
		border-bottom: 1px solid #ddd;
		display: block;
		font-size: 14px;
		text-align: right;
		padding: 5px;
	}

	.modal-header {
		/* height: 35px; */
		height: auto;
		padding: 4px 10px;
	}

	.cr-boundary {
		width: 100% !important;
	}

	.pay_ment td::before {
		content: attr(data-label);
		float: left;
		font-weight: bold;
		text-transform: uppercase;
	}

	.pay_ment td:last-child {
		border-bottom: 0;
	}

	/* hr.header_line {
		height: 40px;
	} */

	.top_head {
		margin-top: 0
	}

	.header_box img {
		width: 140px;
		height: 42px;
	}

	.profile_table td.tab-d-print {
		padding-left: 0 !important;
	}

	.dropdown a.custom_btn {
		position: relative;
		display: block;
		margin: 0;
	}

	.dropdown a {
		display: flex !important;
		align-items: center;
	}


	.dropdown a span {
		max-width: 147px;
		display: block;
		vertical-align: middle;
	}

	ul li ul.dropdown-menu-top {
		position: relative;
	}

	ul li ul.dropdown-menu-top li {
		margin: 0 !important;
		position: absolute;
		border: 2px solid #d6e4d6;
		width: 100%;
		padding-left: 52px;
		min-width: 147px;
		z-index: 1002;
		color: #fff;
		background: #fff;
		padding-top: 4px;

	}

	.fee {
		margin-bottom: 20px;
	}

	.half_col {
		display: grid;
		grid-gap: 9px;
		justify-content: inherit;
	}

	.line {
		padding: 0px 4px;
	}

	.mark_btn input {
		margin: 10px 5px;
	}

	span.tooltip {
		width: auto;
		max-width: 300px;
		font-size: 10px;
	}

	#progressbar li {
		font-size: 10px;
	}

	.tabset>label {
		padding: 8px 7px;
		width: 33%;
		font-size: 13px;
	}

	ul#progressbar p {
		font-size: 9px;
	}

	.head_table {
		background: #00743d;
		color: #fff;
		padding: 0 13px;
	}

	fieldset {
		position: relative;
	}

	.dropdown-menu-top {
		padding-bottom: 0;
	}

	.help_wrapper table td {
		text-align: left;
	}

	.search_grid-upload-img .grid-box {
		width: 100%;
	}

	.grid-personal,
	.grid-two,
	.grid-container,
	.grid-payment,
	.search_grid,
	.grid-one-third,
	.grid-passport {
		grid-template-columns: 100%;
	}

	.clearfix {
		display: none;
	}

	details {
		display: block;
	}

	.instruction_box {
		display: none;
	}

	.grid-container {
		grid-gap: 0;
	}

	.banner_text {
		top: 6%;
		left: 5%;
	}

	.banner-img_home {
		height: 180px;
	}

	.instruction_box {
		margin-top: 2px;
		padding: 1px 10px 5px;
	}

	.tab-panel p {
		padding-left: 0;
	}

	.new_app .save_btn,
	.new_app .custom_btn {
		font-size: 13px;
	}

	.align-right {
		top: 4px;
	}

	hr.header_line {
		margin: 0;
	}

	.main-bottom {
		margin-bottom: 10px;
	}

	.t_and_c {
		margin-top: 0;
	}

	.t_and_c .pass-det {
		height: auto;
	}

	.banner-img-name {
		/* margin-bottom: 8px; */
	}

	.support_document table tr td {
		text-align: left;
		border-bottom: none;
		height: auto;
		padding: 2px 4px 2px;
	}

	.support_document table tr td .alert {
		left: 0;
		bottom: 2px;
	}

	.support_document table tr td:last-child {
		padding-bottom: 8px;
	}

	.support_document table tr td .alert {
		position: inherit;
		padding-top: 0;
	}

	table {
		height: auto;
		margin-bottom: 0;
	}

	.grid-two-box {
		margin-bottom: 0;
	}

	.grid-two,
	.grid-personal {
		grid-gap: 0;
		position: relative;
	}

	.view-buttons {
		margin-bottom: 60px;
	}

	.cus_row {
		margin: 0;
		width: 100%;
		float: left;
	}

	.menu.no-menu {
		top: 57px;
	}

	/* .header-session .right_sides.right_top_1 {
		width: calc(100% - 150px);
	} */
	.cus_row input {
		margin: 0 !important
	}

	.faq-text span {
		width: 100%;
		float: left;
	}

	.pay-recipt {
		width: 100%;
	}

	.user_type ul li label {
		padding: 17px 20px 5px;
	}

	.user_type ul li .check {
		top: 20px;
		left: 0;
	}

	.shop-cart .custom_btn {
		padding: 7px 15px !important;
	}

	.main1 {
		width: 100%;
		padding-right: 20px;
		padding-left: 20px;
		height: auto;
	}

	.grid-box {
		text-align: left;
	}

	.pay_input input {
		font-size: 12px;
		padding-top: 4px;
	}

	.fix_height {
		height: auto;
	}

	.cart-info {
		height: 67px;
		overflow-y: auto;
		margin-bottom: 15px;
	}

	.dropdown-menu-top li a {
		color: #000;
	}

	.personal_details_section table {
		width: 100%;
		border: 1px solid #c6c5c5;
	}

	.personal_details_section table tr {
		width: 100% !important;
		border-bottom: 0 !important;
	}

	.personal_details_section table tr td:first-child {
		width: 100%;
		padding-left: 5px;
		text-align: left;
	}

	.personal_details_section table tr td {
		width: auto;
		text-align: left;
		border-left: 1px solid #ddd;
	}

	.personal_details_section table tr td:nth-child(even) {
		width: 100%;
	}

	.card {
		width: 100%;
		padding: 20px 15px;
	}

	.confirmbox-container,
	.ngx-dialog-content {
		width: 95% !important;
	}

	.confirmbox-container p {
		padding: 13px 0 !important;
	}

	.modal-content {
		height: auto;
	}

	.profile_view li {
		padding-left: 0;
	}

	.profile_view ul {
		grid-template-columns: 100%;
	}

	.fr1 {
		float: none;
	}

	.grid_detail {
		grid-template-columns: 100%;
		grid-gap: 0;
	}

	.grid_detail-box {
		padding: 0;
	}

	.grid_detail-box:first-child {
		border-right: 0;
	}

	.grid-modal-container {
		grid-template-columns: 37% 36% 18%;
		grid-gap: 10px;
	}

	.user_type,
	.profile_type {
		width: 100%;
		margin-bottom: 90px;
	}

	.grid-modal-box .btn {
		margin: 20px 0 !important;
	}

	.profile_type .form_box {
		height: 75px;
	}

	.profile_type .custom_btn {
		margin: 10px 5px;
	}

	/* footer {
		position: unset;
	} */
	fieldset.plr10 {
		padding: 0px 8px;
	}

	#msform {
		padding: 8px 7px;
	}


	/* New CSS Added by GS */
	.grid_three {
		grid-template-columns: 100%;
		grid-gap: 13px;
	}

	.card_box_pass {
		margin-bottom: 8px;
		padding: 9px 4px;
	}

	.card_box_pass a {
		font-size: 13px;
	}

	.login_nav {
		display: flex !important;
		float: none;
		justify-content: flex-end;
	}

	.mark_btn {
		width: 100%;
		float: left;
	}

	/* nav {
		box-shadow: 0 14px 28px rgb(0 0 0 / 9%), 0 10px 10px rgb(0 0 0 / 9%);
		height: auto;
	} */

	.h-1 {
		min-height: auto;
	}

	.radio label {
		margin-bottom: 15px;
	}

	.mt15 {
		margin-top: 0;
	}

	h4.seprate {
		padding: 0 0 15px;
	}

	.common_view,
	.upper_bar {
		justify-content: flex-start;
		padding: 0 5px;
	}

	.common_view p {
		/* width: auto !important;
		float: right !important; */
		margin-right: 0 !important;
	}

	.visa_type p {
		Font-size: 17px;
	}

	.sr-p-info p {
		margin: 5px 0 0;
		line-height: 19px;
	}

	.tab50 div {
		padding: 2px 6px;
	}

	.sr-p-info label {
		line-height: 14px;
	}

	.half_col_box {
		width: 100%;
	}

	.modal-footer .back_btn {
		margin-left: 8px;
	}

	.modal-body {
		padding: 4px 8px 8px;
	}

	.notice_modal {
		max-width: 95% !important;
	}

	.notice_modal .modal-body {
		padding: 6px;
	}

	.faq {
		margin: 10px;
	}

	table.pay_ment {
		border: 1px solid #dfdfdf;
	}

	footer img {
		width: 68px;
		height: 38px;
	}

	.h_txt {
		height: 135px;
	}

	.sr-p-info div {
		padding: 5px 5px;
	}

	.box_row h4 {
		padding-left: 0px;
	}

	.main-footer {
		padding-right: 2px;
		padding-left: 2px;
		width: 100%;
	}

	.footer_flex p {
		font-size: 0.83em;
	}

	.form_box.h_txt.height-form {
		height: 73px !important;
	}

	.tabordion section {
		display: inline-block;
	}

	.tabordion section label {
		font-size: 14px;
		width: 90px;
		padding: 5px 15px;
	}

	.tabordion section article {
		left: 0;
		min-width: auto;
		top: auto;
	}

	.form_box {
		height: 73px;
	}

	.tabs-container,
	.tab-content {
		width: 100%;
		height: auto;
	}

	.tab-content {
		border-left: 1px solid #d7d7d7;
	}

	table th {
		border: none;
		clip: rect(0 0 0 0);
		/* height: 1px; */
		margin: -1px;
		overflow: hidden;
		padding: 0;
		position: absolute;
		/* width: 1px */
	}

	table tr {
		border-bottom: 1px solid #0d7836;
		display: table;
		width: 100%;
	}

	.history_info-inner table td,
	.history_info-inner table tr {
		float: left;
	}

	table td {
		border-bottom: 1px solid #ddd;
		display: block;
		text-align: right;
		padding: 5px 6px;
		border-left: 0;
		height: auto;
	}

	.profile_table.standard-prof-table td {
		border-bottom: none;
	}

	.profile_table.documentt_view tr {
		border-bottom: 1px solid #979797 !important;
	}

	td:empty {
		display: none;
	}

	table td:before {
		content: attr(data-label);
		float: left;
		font-family: sfui-semibold;
		text-transform: capitalize
	}

	.passport_fees table {
		margin-bottom: 0;
	}

	.passport_fees table tr td {
		border-bottom: 0 !important;
		font-size: 0.85em !important;
		line-height: 1;
	}

	.upper_bar,
	.common_view {
		padding: 0 5px;
	}

	.visa_type p {
		font-size: 15px;
	}

	.common_view label,
	.common_view p {
		font-size: 15px;
		padding-bottom: 5px;
	}

	.passport_fees {
		padding: 4px 7px;
	}

	.passport_fees table td,
	.pay-recipt_table tr td,
	.pageWrapper table tr td {
		width: auto;
		text-align: left;
		border-bottom: 1px solid #d7d7d7 !important;
	}

	.passport_fees table td:nth-child(even),
	.pay-recipt_table tr td:nth-child(even) {
		float: right;
	}

	.info h3 {
		font-size: 14px;
		line-height: 1.2;
	}

	.half_w {
		width: 100%;
	}

	table.pay-recipt_table td {
		border-bottom: 0 !important;
		font-size: 0.87em;
	}

	.cod-Data h2 {
		font-size: 20px;
	}

	.detail_change_view {
		padding: 0;
	}

	.top_pass h3 {
		font-size: 15px;
	}

	.mid-section h4 {
		font-size: 20px;
	}

	.form-card {
		padding: 8px 8px;
	}

	.legends span {
		height: 18px;
		margin-top: 3px;
	}

	.legends span p {
		font-size: 12px;
	}

	/* .wrapper {
		top: 76px;
	} */

	.wrapper.home_fix {
		top: 49px !important;
	}

	.home {
		margin-bottom: 40px;
	}

	.imp_info {
		display: none;
	}

	.search_grid {
		grid-template-columns: 100%;
	}

	.mob_adjust {
		display: none;
	}

	.mob_legends {
		width: 100%;
		float: left;
		font-size: 11px;
		padding-top: 5px;
	}

	.mob_legends {
		font-size: 10px;
	}

	.mob_legends p:after {
		content: ' ';
		border: 1px solid #b7b6b6;
		height: 10px;
		width: 0px;
		margin: 0 3px 0 4px;
		display: inline-block;
		vertical-align: middle;
	}

	.mob_legends p:last-child:after {
		display: none;
	}

	.mob_legends i {
		font-size: 10px;
	}

	.mob_font {
		font-size: 10px;
	}

	.height_fixx {
		height: 100%;
		max-height: calc(100vh - 350px);
		overflow-y: auto;

	}

	.bottom-deatils-content {
		position: unset;
	}

	.mob_legends p {
		display: inline-block;
		padding-left: 0;
	}

	.margin0 {
		margin: 0 !important;
	}

	.page {
		padding: 30px 0 0;
	}

	.right_sides.right_top_1 {
		order: 2;
		column-gap: 10px;
	}

	.menu-button,
	.menu-button::before,
	.menu-button::after {
		width: 25px;
	}

	/* .type-line {
		order: 3;
		display: inline-block;
		width: 100%;
		border-top: 1px solid #cacbca;
	} */
	.right_top.type-line {
		padding: 0;
	}

	.custom_btn1 {
		font-size: 12px;
	}

	nav {
		padding: 5px 0 0;
	}

	.user_log {
		order: 1;
	}

	.menu.menu_roww ul li a {
		color: #000;
	}

	.menu.menu_roww {
		position: relative;
		color: #000;
		top: -8px;
		margin-top: 0;
	}

	table.documentt_view tr td:first-child {
		width: 90%;
	}

	table.documentt_view tr td:last-child {
		width: 10%;
	}

	table.documentt_view tr {
		display: flex;
	}

	.none_dis {
		display: none;
	}

	.guidelines-container,
	.cod-popup {
		width: calc(100% - 20px) !important;
	}

	.confirmbox-container,
	.image_upload_container {
		width: 95% !important;
	}

	table.verify-table.verify-table-box td,
	table.table-code-req td {
		padding: 8px 6px;
	}

	table.dashboard-table {
		border: none;
	}

	table.dashboard-table tr {
		border: 1px solid #cacbca;
	}

	table.dashboard-table tr {
		margin-bottom: 8px;
	}

	.image_upload_container.image-upload-large .modal-header {
		padding: 0 10px;
	}

	.re_upload_box {
		width: 100%;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
	}

	.re-upload-info_top {
		padding: 0 0 10px;
		align-items: center;
	}

	.history_modal_info {
		width: 100%;
		min-width: 100%;
		padding: 0;
	}

	.history_info-inner table td {
		display: table-cell;
		text-align: left;
		height: auto;
		min-height: 34px;
	}

	.history_info-inner table th {
		position: relative;
		height: auto;
		padding: 0 5px;
	}

	.history_info-inner table tr {
		table-layout: fixed;
		width: 100%;
	}

	.history_info-inner table td:nth-child(2) {
		border-left: 1px solid #ddd;
	}

	.grid-modal-box.search-app {
		margin-top: 0;
	}
}

@media (max-width: 575px) {
	td.upload-support-doc {
		width: 55%;
	}

	td.upload-support-doc-btn {
		width: 45%;
	}

	.confirmbox-container {
		min-width: calc(100% - 20px)
	}
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
	.common_view_item {
		width: 50%;
	}

	.search_grid-upload-img .grid-box {
		width: 50%;
	}

	.image_upload_container .modal-body {
		max-height: 580px;
	}

}

@media (max-width: 479px) {
	.common_view_item {
		width: 100%;
		display: flex;
	}

	.result-header .user_pic {
		border-right: none;
		align-items: center;
		justify-content: center;
		width: 100%;
		border-bottom: 1px solid #ada7a7
	}

	.s-head .result-header {
		flex-wrap: wrap;
	}

	.header-session .right_sides.right_top_1 {
		width: calc(100% - 198px);
	}

	.tabordion-list li {
		font-size: 14px;
		padding: 10px;
	}

	.tabordion-output {
		width: calc(100% - 130px);
		padding: 0 0 0 20px;
	}

	.back_btn,
	.btn,
	.custom_btn,
	.edit_btn,
	.receipt_btn,
	.save_btn {
		font-size: 12px;
	}

	.history_info-inner td .danger {
		font-size: 9px;
	}

}

@media only screen and (max-width: 450px) {
	.image-upload-large .modal-header h4 {
		font-size: 10px;
	}

	.tabset>label {
		min-width: 99px;
	}

	.image-cropper-box {
		height: calc(100% - 55px);
	}

	.image_upload_container .centered .grid-one-third {
		height: calc(100% - 43px);
	}
}

@media (max-width: 390px) {
	td.upload-support-doc {
		width: 50%;
	}

	td.upload-support-doc-btn {
		width: 50%;
	}

	#msform {
		padding: 12px 6px 8px !important;
	}

	.book_center.ensure-txt {
		font-size: 10px;
	}

	.image_upload_container.image-upload-large #msform {
		padding: 0 !important;
	}

}

@media only screen and (min-width: 320px) and (max-width: 380px) {

	body {
		font-size: 13px;
	}

	.new_app .save_btn,
	.new_app .custom_btn {
		font-size: 12px;
	}

	.tabset>label {
		width: 33%;
		font-size: 12px;
	}

	table td {
		padding: 2px 6px 1px;
	}

	table.verify-table.verify-table-box td,
	table.table-code-req td {
		padding: 8px 6px;
	}

	.height_fixx {
		max-height: calc(100vh - 270px) !important;
		border-bottom: 1px solid #aeaeae;
	}

	.mark_btn input {
		margin: 10px 5px;
	}

	.tab-panel p {
		padding-left: 0;
	}

	.legends span {
		height: 14px;
		margin-top: 0px;
	}

	.gurantor-form {
		overflow-x: auto;
	}

	.modal-header .list-doc {
		padding: 10px;
		font-size: 14px;
	}

	.photo_box.photo-set {
		height: 300px;
	}

	.cr-boundary {
		height: 300px !important;
	}

	.mt-8 {
		margin-top: 8px;
	}

	.image_upload_container.image-upload-large .modal-header {
		height: 45px;
	}


}

@media (max-height: 700px) {
	.image_upload_container.image-upload-large .modal-body {
		height: calc(100vh - 155px);
	}
}

.mat-select-panel-wrap {
	position: relative;
	top: 87px;
}

.mat-select-panel-wrap .mat-select-panel {
	min-width: calc(100% + 10px) !important;
	transform: translateX(11px) !important
}